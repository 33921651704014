<template>
  <div>
    <v-row class="align-center mb-3">
      <v-col>
        <v-text-field
          v-model="email"
          @change="handleFilter()"
          label="Filter by email"
          hide-details
          class="mt-0 pt-0"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="firstName"
          @change="handleFilter()"
          label="Filter by First Name"
          hide-details
          class="mt-0 pt-0"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="lastName"
          @change="handleFilter()"
          label="Filter by Last Name"
          hide-details
          class="mt-0 pt-0"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-text-field
          v-model="username"
          @change="handleFilter()"
          label="Filter by Username"
          hide-details
          class="mt-0 pt-0"
        ></v-text-field>
      </v-col>
      <v-col>
        <v-menu offset-y :close-on-content-click="false">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark small v-bind="attrs" v-on="on">
              Filters
            </v-btn>
          </template>
          <draggable
            v-model="headers"
            group="groups"
            @start="dragging = true"
            @end="dragging = false"
            style="max-height: 300px; background: #fff; overflow-y: auto"
            tag="div"
          >
            <div v-for="(group, i) in headers" :key="i">
              <div class="d-flex align-center">
                <v-checkbox
                  dense
                  hide-details
                  class="mt-0 pt-0"
                  :label="group.group"
                  v-model="group.visible"
                ></v-checkbox>
              </div>

              <!-- Draggable for Items within a Group -->
              <draggable
                :list="group.children"
                :group="{ name: 'items', pull: 'clone', put: false }"
                :disabled="dragging"
                tag="ul"
                style="list-style: none; padding-left: 32px"
              >
                <li v-for="item in group.children" :key="item.text">
                  <small>{{ item.text }}</small>
                </li>
              </draggable>
            </div>
          </draggable>
        </v-menu>
      </v-col>
    </v-row>

    <v-data-table
      :headers="flattenedHeaders"
      :items="filteredClients.data"
      :page.sync="currentPage"
      :items-per-page.sync="perPage"
      :server-items-length="totalClients"
      :loading="loading"
      class="elevation-1"
      hide-default-header
      fixed-header
      :footer-props="{
        'items-per-page-options': [10, 20, 25, 50, 100],
      }"
    >
      <template v-slot:top>
        <thead class="v-data-table-header custom-header">
          <draggable tag="tr" :list="headers" v-bind="dragOptions">
            <th
              :colspan="item.children.length"
              v-show="item.visible"
              class="text-center border-right border-bottom"
              v-for="item in headers"
              :key="item.id"
            >
              {{ item.group }}
            </th>
          </draggable>
          <tr>
            <th
              class="text-start"
              v-for="(header, index) in flattenedHeaders"
              :key="index"
              style="width: 200px; min-width: 200px"
            >
              {{ header.text }}
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item.isDisabled="{ item }">
        <div class="d-flex align-center">
          <v-checkbox
            class="me-2"
            v-model="item.isDisabled"
            readonly
            plain
            :ripple="false"
          ></v-checkbox>
          <v-btn
            v-if="!isChiefTherapist"
            class="ms-auto"
            icon
            @click="openIsDisabledDialog(item)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.profile.createdAt="{ item }">
        {{ item.profile.createdAt | moment("DD-MM-YYYY LT") }}
      </template>

      <template v-slot:item.currentPromotionCode.corporatePlan.code="{ item }">
        <template
          v-if="item.currentPromotionCode && item.currentPromotionCode.code"
        >
          {{ item.currentPromotionCode.code }}

          <v-btn class="ms-auto" icon @click="removeBenefitsCode(item)">
            <v-icon>mdi-trash-can</v-icon>
          </v-btn>
        </template>
      </template>

      <template v-slot:item.company.name="{ item }">
        {{ item.company.type === "company" ? item.company.name : "-" }}
      </template>
      <template v-slot:item.company.name="{ item }">
        {{
          item.company && item.company.type !== "Insurance"
            ? item.company.name
            : "-"
        }}
      </template>
      <template v-slot:item.insuranceName="{ item }">
        {{
          item.company && item.company.type === "Insurance"
            ? item.company.name
            : "-"
        }}
      </template>
      <template v-slot:item.currentPromotionCode.corporatePlan.name="{ item }">
        {{
          item.currentPromotionCode && item.company.type !== "Insurance"
            ? item.currentPromotionCode.corporatePlan.name
            : "-"
        }}
      </template>
      <template v-slot:item.insurancePlan="{ item }">
        {{
          item.currentPromotionCode && item.company.type === "Insurance"
            ? item.currentPromotionCode.corporatePlan.name
            : "-"
        }}
      </template>
      <template v-slot:item.insurance.status="{ item }">
        <v-icon
          v-if="item.currentPromotionCode && item.company.type === 'Insurance'"
          small
          class="mr-2"
          @click="openEditDialog(item)"
        >
          mdi-pencil
        </v-icon>
      </template>

      <template v-slot:item.subscriptionStartDate="{ item }">
        {{ getUserSubscriptionStartDate(item) }}
      </template>

      <template v-slot:item.subscriptionEndDate="{ item }">
        {{ getUserSubscriptionEndDate(item) }}
      </template>

      <template v-slot:item.subscriptionRenewalDate="{ item }">
        {{ getUserSubscriptionEndDate(item) }}
      </template>
      <template v-slot:item.therapistEmail="{ item }">
        {{
          item.therapist && item.therapist.email ? item.therapist.email : "-"
        }}
      </template>
      <template v-slot:item.therapistName="{ item }">
        {{
          item.therapist && item.therapist.profile
            ? item.therapist.profile.firstName +
              " " +
              item.therapist.profile.lastName
            : "-"
        }}
      </template>

      <template v-slot:item.metadata.numberLiveSessionsHave="{ item }">
        <div class="d-flex align-center">
          <span class="me-2">
            {{ item.metadata.numberLiveSessionsHave }}
          </span>
          <v-btn
            v-if="!isChiefTherapist"
            class="ms-auto"
            icon
            @click="openLiveSessionAddDialog(item, false)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.metadata.numberCorporateLiveSessionsHave="{ item }">
        <div class="d-flex align-center">
          <span class="me-2">
            {{ item.metadata.numberCorporateLiveSessionsHave }}
          </span>

          <v-btn
            v-if="!isChiefTherapist && item.company"
            class="ms-auto"
            icon
            @click="openLiveSessionAddDialog(item, false, true)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>

      <template v-slot:item.metadata.numberFreeLiveSessionsHave="{ item }">
        <div class="d-flex align-center">
          <span class="me-2">
            {{ item.metadata.numberFreeLiveSessionsHave }}
          </span>
          <v-btn
            v-if="!isChiefTherapist"
            class="ms-auto"
            icon
            @click="openLiveSessionAddDialog(item, true)"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>

    <ClientIsDisabledChange ref="ClientIsDisabledChangeRef" />
    <ClientDatabaseCreateEdit
      ref="clientDatabaseCreateEditRef"
      :insuranceCompanies="insuranceCompanies"
      @onSave="getCompanyAndInsuranceUsersData"
    />
    <ClientLiveSessionAdd ref="ClientLiveSessionAddRef" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import UserMixin from "@/mixins/UserMixin";
import PatientMixin from "@/mixins/PatientMixin";
import draggable from "vuedraggable";
import ClientIsDisabledChange from "@/components/Admin/ClientIsDisabledChange";
import ClientDatabaseCreateEdit from "@/components/Admin/CorporateProgram/ClientDatabaseCreateEdit.vue";
import ClientLiveSessionAdd from "@/components/Admin/ClientLiveSessionAdd.vue";

export default {
  name: "ClientLogV2",
  mixins: [UserMixin, PatientMixin],
  components: {
    ClientLiveSessionAdd,
    draggable,
    ClientIsDisabledChange,
    ClientDatabaseCreateEdit,
  },
  data: () => {
    return {
      insuranceCompanies: [],

      email: "",
      firstName: "",
      lastName: "",
      username: "",

      dragging: false,
      clientsForAdmin: [],
      loading: true,
      currentPage: 1,
      perPage: 10,
      totalClients: 0,
      headers: [
        {
          group: "Client Details",
          visible: true,
          children: [
            {
              text: "First Name",
              align: "start",
              sortable: true,
              value: "profile.firstName",
            },
            {
              text: "Last Name",
              align: "start",
              sortable: true,
              value: "profile.lastName",
            },
            {
              text: "Username",
              align: "start",
              sortable: true,
              value: "profile.username",
            },
            {
              text: "DOB",
              align: "start",
              sortable: true,
              value: "profile.birthDate",
            },
            {
              text: "Gender",
              align: "start",
              sortable: true,
              value: "profile.gender",
            },
            {
              text: "Email",
              align: "start",
              sortable: true,
              value: "email",
            },
            {
              text: "Signup Date",
              align: "start",
              sortable: true,
              value: "profile.createdAt",
            },
            {
              text: "Access",
              align: "start",
              sortable: false,
              value: "isDisabled",
            },
          ],
        },
        {
          group: "Corprate Benefits",
          visible: true,
          children: [
            {
              text: "Benefits Code",
              align: "start",
              sortable: false,
              value: "currentPromotionCode.corporatePlan.code",
            },
            {
              text: "Company Name",
              align: "start",
              sortable: false,
              value: "company.name",
            },
            {
              text: "Plan",
              align: "start",
              sortable: false,
              value: "currentPromotionCode.corporatePlan.name",
            },
            {
              text: "Status",
              align: "start",
              sortable: false,
              value: "company.status",
            },
          ],
        },
        {
          group: "Insurance Benefits",
          visible: true,
          children: [
            {
              text: "Insurance Policy number",
              align: "start",
              sortable: false,
              value: "insurancePolicyNumber",
            },
            {
              text: "Insurance Name",
              align: "start",
              sortable: false,
              value: "insuranceName",
            },
            {
              text: "Plan",
              align: "start",
              sortable: false,
              value: "insurancePlan",
            },
            {
              text: "Status",
              align: "start",
              sortable: false,
              value: "insurance.status",
            },
          ],
        },
        {
          group: "Personal",
          visible: true,
          children: [
            {
              text: "Subscription Start Date",
              align: "start",
              sortable: false,
              value: "subscriptionStartDate",
            },
            {
              text: "Subscription End Date",
              align: "start",
              sortable: false,
              value: "subscriptionEndDate",
            },
            {
              text: "Renewal End Date",
              align: "start",
              sortable: false,
              value: "subscriptionRenewalDate",
            },
          ],
        },
        {
          group: "Session Credits",
          visible: true,
          children: [
            {
              text: "Corporate/Insurance",
              align: "start",
              sortable: false,
              value: "metadata.numberCorporateLiveSessionsHave",
            },
            {
              text: "Personal",
              align: "start",
              sortable: false,
              value: "metadata.numberLiveSessionsHave",
            },
            // {
            //   text: "Corporate",
            //   align: "start",
            //   sortable: false,
            //   value: "metadata.numberCorporateLiveSessionsHave",
            // },
            {
              text: "Free (20min)",
              align: "start",
              sortable: false,
              value: "metadata.numberFreeLiveSessionsHave",
            },
          ],
        },
        {
          group: "Activity",
          visible: true,
          children: [
            {
              text: "Last session Date",
              align: "start",
              sortable: false,
              value: "lastEvent.eventStartDate",
            },
            {
              text: "Last Session Status",
              align: "start",
              sortable: false,
              value: "lastEvent.status",
            },
            {
              text: "Therapist Name",
              align: "start",
              sortable: false,
              value: "therapistName",
            },
            {
              text: "Therapist Email",
              align: "start",
              sortable: false,
              value: "therapistEmail",
            },
            {
              text: "Notes",
              align: "start",
              sortable: false,
              value: "notes",
            },
          ],
        },
      ],
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 600,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },

    flattenedHeaders() {
      return this.headers.reduce((acc, header) => {
        // Check if the header is visible
        if (header.visible) {
          // If visible, include its children in the accumulator
          acc = acc.concat(
            header.children.map((child) => ({
              ...child,
              visible: header.visible,
            }))
          );
        }
        return acc;
      }, []);
    },

    filteredClients() {
      let filteredClients = JSON.parse(JSON.stringify(this.clientsForAdmin));
      if (!this.search) {
        return filteredClients;
      }

      return this.searchArray(
        this.search,
        JSON.parse(JSON.stringify(filteredClients))
      );
    },
  },

  watch: {
    currentPage: "fetchData",
    perPage: "fetchData",
  },

  methods: {
    ...mapActions("corporateProgram", ["getInsuranceCompanies"]),
    ...mapActions("user", [
      "getCompanyAndInsuranceUsers",
      "getClientsLogForAdminV2",
      "removePromoCodeFromUser",
    ]),

    openLiveSessionAddDialog(item, isFree = false, isCorporate = false) {
      this.$refs.ClientLiveSessionAddRef.open(item, isFree, isCorporate);
    },

    openIsDisabledDialog(item) {
      this.$refs.ClientIsDisabledChangeRef.open(item);
    },

    openEditDialog(item) {
      this.$refs.clientDatabaseCreateEditRef.open(item);
    },

    async getInsuranceCompaniesData() {
      this.insuranceCompanies = await this.getInsuranceCompanies();
    },

    async getCompanyAndInsuranceUsersData() {
      this.loading = true;
      this.users = await this.getCompanyAndInsuranceUsers();
      this.loading = false;
    },

    handleFilter() {
      this.currentPage = 1;
      this.fetchData();
    },

    async fetchData(sortData) {
      // TODO: NEED REFACTOR

      // const filter = JSON.stringify({"therapist": {"$ne": null}, "email": this.email});
      let filterObj = {};
      let sort = sortData ? { ...sortData } : { createdAt: -1 };
      if (this.firstName) {
        filterObj["profile.firstName"] = this.firstName;
      }
      if (this.lastName) {
        filterObj["profile.lastName"] = this.lastName;
      }
      if (this.username) {
        filterObj["profile.username"] = this.username;
      }

      if (this.email) {
        filterObj.email = this.email;
      }

      const filter = JSON.stringify(filterObj);
      const sortBy = JSON.stringify(sort);

      const params = {
        page: this.currentPage,
        limit: this.perPage,
        filter: filter,
        sort: sortBy,
      };

      const query = new URLSearchParams(params).toString();
      const baseURL = process.env.VUE_APP_SERVER_URL;
      const url = `${baseURL}/v2/user/clients-info-for-admin?${query}`;

      this.loading = true;
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + this.$store.state.user.accessToken, // Adjust according to your state management
            "Content-Type": "application/json", // Add this line if your server expects the Content-Type header
          },
        }).then((res) => res.json());
        this.clientsForAdmin = response;
        this.totalClients = this.clientsForAdmin.totalItems;
        this.currentPage = this.clientsForAdmin.currentPage;
      } catch (error) {
        // Handle the error according to your application's needs
      } finally {
        this.loading = false;
      }
    },

    async removeBenefitsCode(item) {
      if (confirm("Remove Benefits code ?")) {
        this.loading = true;
        try {
          this.users = await this.removePromoCodeFromUser({ userId: item._id });
          this.loading = false;

          await this.fetchData();
        } catch (e) {
          console.error("=>(ClientLogV2.vue:606) Error", e);
          this.loading = false;
        }
        console.log("=>(ClientLogV2.vue:602) item", item);
      }
    },
  },

  mounted() {
    this.getCompanyAndInsuranceUsersData();
    this.getInsuranceCompaniesData();

    this.awaitPromiseCallback({
      key: "getMe",
      cb: async () => {
        this.loading = true;
        // let query = {
        //   createdAt: -1,
        // };
        let sort = { createdAt: -1 };

        const sortBy = JSON.stringify(sort);

        const params = {
          page: this.currentPage,
          limit: this.perPage,
          sort: sortBy,
        };

        const query = new URLSearchParams(params).toString();
        this.clientsForAdmin = await this.getClientsLogForAdminV2(query);
        this.totalClients = this.clientsForAdmin.totalItems;
        // this.totalItems = this.clientsForAdmin.totalItems
        this.loading = false;
      },
    });

    const headersDiv = document.querySelector(".custom-header");
    const tableWrapper = document.querySelector(".v-data-table__wrapper table");
    tableWrapper.prepend(headersDiv);
  },
};
</script>

<style scoped lang="scss">
.v-data-table::v-deep .v-data-table__wrapper {
  max-height: 600px !important;
}

th:hover {
  cursor: move !important;
}

.ghost {
  opacity: 0.5;
  background: var(--v-primary-base) !important;
}

.flip-list-move {
  transition: transform 0.5s;
}
</style>
