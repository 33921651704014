<template>
  <div>
    <v-data-table
      :sort-by="['createdAt']"
      :sort-desc="true"
      fixed-header
      :locale="$vuetify.rtl ? 'ar' : 'en'"
      :loading="loading"
      :headers="headers"
      :items="filteredUsers"
      class="elevation-0 bg-transparent"
      calculate-widths
      :custom-sort="customSort"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>
      <template v-slot:item.type="{item}">
        {{ item.company && !item.insurance ? "Corporate" : "Insurance" }}
      </template>
      <template v-slot:item.insurancePolicyNumber="{item}">
        {{ item.insurance ? item.insurance.insurancePolicyNumber : "-" }}
      </template>
      <template v-slot:item.code="{item}">
        {{ item.company ? item.lastPromoCode || "-" : "-" }}
      </template>
      <template v-slot:item.plan="{item}">
        {{ getUserPlanName(item) }}
      </template>
      <template v-slot:item.firstName="{item}">
        {{ item.insurance ? item.insurance.firstName : "-" }}
      </template>
      <template v-slot:item.lastName="{item}">
        {{ item.insurance ? item.insurance.lastName : "-" }}
      </template>
      <template v-slot:item.username="{item}">
        {{ item.company ? "-" : "-" }}
      </template>
      <template v-slot:item.therapistEmail="{item}">
        {{ item.therapist && item.therapist.email ? item.therapist.email : '-' }}
      </template>
      <template v-slot:item.therapistName="{item}">
        {{ item.therapist && item.therapist.profile ? item.therapist.profile.firstName + ' ' + item.therapist.profile.lastName : '-' }}
      </template>

      <template v-slot:item.dob="{item}">
        {{ item.insurance ? item.insurance.birthDate : "-" }}
      </template>
      <template v-slot:item.gender>
        {{ "-" }}
      </template>
<!--      <template v-slot:item.createdAt="{item}">-->
<!--        {{ item.insurance ? getSignUpDate(item.insurance.createdAt) : getSignUpDate(item.company.createdAt) }}-->
<!--      </template>-->
      <template v-slot:item.customCreatedAt="{item}">
        {{ formatDateTime(getCustomCreatedAt(item)) }}
      </template>
      <template v-slot:item.companyName="{item}">
        {{ item.insurance ? item.insurance.name || "-" : item.company.name || "-" }}
      </template>
      <template v-slot:item.status="{item}">
        {{ item.insurance ? item.insurance.status || "-" : item.company.status || "-" }}
      </template>
      <template v-slot:item.actions="{ item }">
        <div class="mr-auto text-no-wrap">


          <v-icon small class="mr-2" @click="openEditDialog(item)">
            mdi-pencil
          </v-icon>
          <!--              <v-icon-->
          <!--                  v-if="canDelete"-->
          <!--                  small-->
          <!--                  @click="removeItem(item)"-->
          <!--              >-->
          <!--                mdi-delete-->
          <!--              </v-icon>-->
        </div>
      </template>
    </v-data-table>

    <ClientDatabaseCreateEdit
        ref="clientDatabaseCreateEditRef"
        :insuranceCompanies="insuranceCompanies"
        @onSave="getCompanyAndInsuranceUsersData"
    />
  </div>

</template>


<script>
import moment from "moment";
import { mapActions } from "vuex";
import ClientDatabaseCreateEdit from "@/components/Admin/CorporateProgram/ClientDatabaseCreateEdit.vue";

export default {
  name: "ClientDatabase",
  components: {
    ClientDatabaseCreateEdit
  },

  data: () => {
    return {
      search: "",
      loading: false,
      users: [],
      insuranceCompanies: [],
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "_id",
          divider: true,
          width: 100
        },
        // {
        //   text: "Name",
        //   align: "start",
        //   sortable: true,
        //   value: "name",
        //   divider: true
        // },
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
          divider: true
        },
        {
          text: "Insurance Policy Number",
          align: "start",
          sortable: true,
          value: "insurancePolicyNumber",
          divider: true
        },
        {
          text: "Code",
          align: "start",
          sortable: true,
          value: "code",
          divider: true
        },
        {
          text: "Therapist Email",
          align: "start",
          sortable: true,
          value: "therapistEmail",
          divider: true
        },
        {
          text: "Therapist Name",
          align: "start",
          sortable: true,
          value: "therapistName",
          divider: true
        },
        {
          text: "First Name",
          align: "start",
          sortable: true,
          value: "firstName",
          divider: true
        },
        {
          text: "Last Name",
          align: "start",
          sortable: true,
          value: "lastName",
          divider: true
        },
        {
          text: "Username",
          align: "start",
          sortable: true,
          value: "username",
          divider: true
        },
        {
          text: "DOB",
          align: "start",
          sortable: true,
          value: "dob",
          divider: true
        },
        {
          text: "Gender",
          align: "start",
          sortable: true,
          value: "gender",
          divider: true
        },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
          divider: true
        },
        {
          text: "Signup Date",
          align: "start",
          sortable: true,
          value: "customCreatedAt",
          divider: true,
          default: 'desc'
        },
        {
          text: "Company Name",
          align: "start",
          sortable: true,
          value: "companyName",
          divider: true
        },
        {
          text: "Plan",
          align: "start",
          sortable: true,
          value: "plan",
          divider: true
        },
        {
          text: "Status",
          align: "start",
          sortable: true,
          value: "status",
          divider: true
        },
        {
          text: "Notes",
          align: "start",
          sortable: true,
          value: "notes",
          divider: true
        },
        // {
        //   text: "Description",
        //   align: "start",
        //   sortable: true,
        //   value: "description",
        //   divider: true
        // },
        {
          text: "Actions",
          align: "end",
          sortable: false,
          value: "actions",
          divider: true
        }
      ]
    };
  },

  computed: {

    filteredUsers() {
      if (!this.search) {
        return this.sortedUsers;
      }
      return this.searchArray(this.search, JSON.parse(JSON.stringify(this.users)));
    },


    sortedUsers() {
      return this.users.slice().sort((a, b) => {
        const dateA = new Date(this.getCustomCreatedAt(a));
        const dateB = new Date(this.getCustomCreatedAt(b));
        return dateB - dateA;  // For descending order
      })
    }
  },

  methods: {
    ...mapActions("corporateProgram", ["getInsuranceCompanies"]),
    ...mapActions("user", ["getCompanyAndInsuranceUsers"]),

    getCustomCreatedAt(item) {
      return item.insurance ? item.insurance.createdAt : item.company.createdAt;
    },

    formatDateTime(datetime) {
      return moment(datetime).format("L LT");
    },

    customSort(items, sortBy, sortDesc) {
      items.sort((a, b) => {
        const aDate = new Date(a.customCreatedAt || (a.insurance ? a.insurance.createdAt : a.company.createdAt));
        const bDate = new Date(b.customCreatedAt || (b.insurance ? b.insurance.createdAt : b.company.createdAt));
        return (aDate < bDate ? -1 : 1) * (sortDesc[0] ? -1 : 1);
      });
      return items;
    },


    getUserPlanName(item) {
      return item?.currentPromotionCode?.name || '-'
    },

    openEditDialog(item) {
      this.$refs.clientDatabaseCreateEditRef.open(item);
    },

    openCreateDialog() {
      this.$refs.clientDatabaseCreateEditRef.open();
    },

    async getCompanyAndInsuranceUsersData() {
      this.loading = true;
      this.users = await this.getCompanyAndInsuranceUsers();
      this.loading = false;
    },

    async getInsuranceCompaniesData() {
      this.insuranceCompanies = await this.getInsuranceCompanies();
    },

    getSignUpDate(date) {
      return moment(date).format("L LT");
    },

    searchArray(input, dataArray) {
      const searchTerm = input.toLowerCase();
      return dataArray.filter(item => {
        for (const key in item) {
          if (typeof item[key] === 'string' && item[key].toLowerCase().includes(searchTerm)) {
            return true;
          } else if (typeof item[key] === 'object' && item[key] !== null) {
            if (this.searchObject(searchTerm, item[key])) {
              return true;
            }
          }
        }
        return false;
      });
    },

    searchObject(searchTerm, obj) {
      for (const key in obj) {
        if (typeof obj[key] === 'string' && obj[key].toLowerCase().includes(searchTerm)) {
          return true;
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          if (this.searchObject(searchTerm, obj[key])) {
            return true;
          }
        }
      }
      return false;
    },
  },

  mounted() {
    this.getCompanyAndInsuranceUsersData();
    this.getInsuranceCompaniesData();
  }
};
</script>

<style scoped lang="scss">
.v-data-table::v-deep .v-data-table__wrapper {
  max-height: 550px !important;
}
</style>
