<template>
  <v-container fluid>
    <!-- <h1>Clients CRM</h1> -->
    <v-row>
      <v-col>
        <TableActions @change="onTableActions" :tags="tags" />
      </v-col>
    </v-row>

    <v-row class="fill-height">
      <v-col cols="12">
        <v-data-table
          fixed-header
          :locale="$vuetify.rtl ? 'ar' : 'en'"
          :loading="loading"
          :sort-desc="false"
          :headers="headers"
          :items="filteredClients"
          :single-expand="singleExpand"
          :expanded.sync="expanded"
          show-expand
          class="elevation-0 bg-transparent"
          calculate-widths
        >
          <template v-slot:item.Name="{ item }">
            <v-layout flex-nowrap align-center>
              <UserAvatar :user="item" :size="40" />
              <span class="ms-2">{{ getUserName(item) }}</span>
            </v-layout>
          </template>

          <template v-slot:item.TherapistName="{ item }">
            {{ getUserName(item.therapist) }}
          </template>

          <template v-slot:item.SubscriptionType="{ item }">
            {{ getUserSubscriptionPlan(item) }}
          </template>

          <template v-slot:item.SubscriptionStartDate="{ item }">
            {{ getUserSubscriptionStartDate(item) }}
          </template>

          <template v-slot:item.SubscriptionRenewalDate="{ item }">
            {{ getUserSubscriptionEndDate(item) }}
          </template>

          <template v-slot:item.LiveSessionCredit="{ item }">
            <div class="d-flex align-center">
              <span class="me-2">
                {{ getUserLiveSessionCredits(item) }}
              </span>
              <v-btn
                class="ms-auto"
                icon
                @click="openLiveSessionAddDialog(item, false)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.FreeLiveSessionCredit="{ item }">
            <div class="d-flex align-center">
              <span class="me-2">
                {{ getUserFreeLiveSessionCredits(item) }}
              </span>
              <v-btn
                class="ms-auto"
                icon
                @click="openLiveSessionAddDialog(item, true)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.LastSessionDate="{ item }">
            <template v-if="item.lastEvent">
              {{ item.lastEvent.eventStartDate | moment("DD-MMM-YYYY LT") }}
            </template>
            <template v-else>-</template>
          </template>
          <template v-slot:item.LastSessionStatus="{ item }">
            <template v-if="item.lastEvent">
              {{ item.lastEvent.status | moment("DD-MMM-YYYY LT") }}
            </template>
            <template v-else>-</template>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-container>
                <v-row>
                  <v-col md="3">
                    <h2>{{ getUserName(item) }}</h2>
                    <span class="mr-1">CRM Status:</span>
                    <b>Active</b>
                    <br />
                    <span class="mr-1">Therapist:</span>
                    <b>{{ getUserName(item.therapist) }}</b>
                    <br />
                    <v-btn
                      class="rounded-pill border mt-5"
                      text
                      color="primary"
                      @click="chooseClient(item)"
                    >
                      Client Profile
                    </v-btn>
                    <br />

                    <v-btn
                      class="rounded-pill border mt-3"
                      text
                      color="primary"
                      @click="chooseTherapist(item.therapist)"
                    >
                      Therapist Profile
                    </v-btn>
                  </v-col>
                  <v-divider class="my-2" vertical></v-divider>
                  <v-col md="7">
                    <v-container>
                      <v-row>
                        <v-col xl="4" lg="6">
                          <p class="ma-0">Client Email</p>
                          <b>{{ item.email }}</b>
                        </v-col>
                        <v-col xl="4" lg="6">
                          <p class="ma-0">Language Preference</p>
                          <b>{{ item.lang }}</b>
                        </v-col>
                        <v-col xl="4" lg="6">
                          <p class="ma-0">Age</p>
                          <b>35</b>
                        </v-col>
                        <v-col xl="4" lg="6">
                          <p class="ma-0">Client ID</p>
                          <b>{{ item.id }}</b>
                        </v-col>
                        <v-col xl="4" lg="6">
                          <p class="ma-0">Country</p>
                          <b>{{ item.profile.location.country }}</b>
                        </v-col>
                        <v-col xl="4" lg="6">
                          <p class="ma-0">Last Session Date</p>
                          <b v-if="item.lastEvent">
                            {{
                              item.lastEvent.eventStartDate
                                | moment("DD-MMM-YYYY LT")
                            }}
                          </b>
                          <b v-else>-</b>
                        </v-col>
                        <v-col xl="4" lg="6">
                          <p class="ma-0">Registration Date</p>
                          <b>{{ item.createdAt | moment("DD-MMM-YYYY LT") }}</b>
                        </v-col>
                        <v-col xl="4" lg="6">
                          <p class="ma-0">Gender</p>
                          <b>{{ item.profile.gender }}</b>
                        </v-col>
                        <v-col xl="4" lg="6">
                          <p class="ma-0">Last Session Status</p>
                          <b v-if="item.lastEvent">
                            {{
                              item.lastEvent.status | moment("DD-MMM-YYYY LT")
                            }}
                          </b>
                          <b v-else>-</b>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <p>Conditions</p>

                          <v-chip-group
                            active-class="primary--text"
                            dark
                            color="primary"
                            dense
                            column
                          >
                            <v-chip
                              class="me-2 mb-1"
                              v-for="disorder in getUserDisorders(item)"
                              small
                              color="accent"
                              outlined
                              :key="disorder.name"
                            >
                              <b>{{ $t(disorder.name) }}</b>
                            </v-chip>
                          </v-chip-group>

                          <v-chip
                            v-if="getUserDisordersOther(item)"
                            class="me-2 mb-2"
                            small
                            :key="disorder.name"
                          >
                            <b>{{ getUserDisordersOther(item) }}</b>
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-divider class="my-2" vertical></v-divider>
                  <v-col md="2">
                    <v-container>
                      <v-row>
                        <v-col md="12">
                          <p class="ma-0">Company</p>
                          <b>-</b>
                        </v-col>
                        <v-col md="12">
                          <p class="ma-0">Voucher</p>
                          <b>-</b>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>
              </v-container>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <ClientLiveSessionAdd ref="ClientLiveSessionAddRef" />
  </v-container>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";
import PatientMixin from "@/mixins/PatientMixin";
import ClientLiveSessionAdd from "@/components/Admin/ClientLiveSessionAdd";
import TableActions from "./components/TableActions";
import UserAvatar from "@/components/UserAvatar";
import { userApi } from "@/api";
import { mapActions } from "vuex";

export default {
  name: "ClientLog",

  mixins: [UserMixin, PatientMixin],

  components: {
    ClientLiveSessionAdd,
    TableActions,
    UserAvatar,
  },

  data: () => {
    return {
      loading: false,

      clientsForAdmin: [],

      fixedColumns: [
        {
          text: "Client Name",
          align: "start",
          value: "Name",
          width: "200px",
        },
        {
          text: "Therapist Name",
          align: "start",
          value: "TherapistName",
          width: "200px",
        },
      ],
      headers: [],
      expanded: [],
      singleExpand: false,
      dateFilter: null,
      actionsState: {
        dates: [],
      },
      tags: [],
    };
  },

  computed: {
    filteredClients() {
      let filteredClients = JSON.parse(JSON.stringify(this.clientsForAdmin));

      if (this.filterByClientEmail) {
        filteredClients = filteredClients.filter(
          (e) => e._id === this.filterByClientEmail
        );
      }

      if (this.filterByClientName) {
        filteredClients = filteredClients.filter((e) => {
          if (e.profile.username) {
            return e.profile.username === this.filterByClientName;
          } else if (
            !e.profile.username &&
            e.profile.firstName &&
            e.profile.lastName
          ) {
            let name = e.profile.firstName + " " + e.profile.lastName;
            return name.includes(this.filterByClientName);
          }
        });
      }

      return filteredClients;
    },
  },

  methods: {
    ...mapActions("user", ["loginForAdmin"]),

    async updateTags(dto) {
      this.removeActions = true;
      const res = await userApi.getUserFilteringTags(dto);

      this.tags = res.data;

      this.removeActions = false;
    },

    async chooseTherapist(selectedTherapist) {
      await this.loginForAdmin({
        id: selectedTherapist.id,
        email: selectedTherapist.email,
      });

      location.replace("/calendar");
    },

    async chooseClient(selectedClient) {
      await this.loginForAdmin({
        id: selectedClient.id,
        email: selectedClient.email,
      });

      location.replace("/calendar");
    },

    openLiveSessionAddDialog(item, isFree) {
      this.$refs.ClientLiveSessionAddRef.open(item, isFree);
    },

    async onTableActions(actionsState) {
      this.actionsState = actionsState;

      this.headers = [...this.fixedColumns];

      for (const column of actionsState.columnsList) {
        if (column.isEnabled)
          this.headers.push({
            text: column.label,
            align: "start",
            value: column.key,
            divider: true,
            sort(a, b) {
              console.log(a, b);
              return -1;
            },
          });
      }

      this.headers = this.headers.map((h) => {
        return {
          ...h,
          sortable: true,
        };
      });

      await this.showUsers();
    },

    async getUsers(actionsState) {
      const dto = {
        search: actionsState.search,
        tag: actionsState.tag,
        "date.from": actionsState.dates[0],
        "date.to": actionsState.dates[1],
        "filtering.disorder": actionsState.filtering?.disorder?._id,
        "filtering.country": actionsState?.filtering?.country,
        "filtering.language": actionsState.filtering?.language?.code,
        "filtering.gender": actionsState?.filtering?.gender,
        "filtering.therapist": actionsState?.filtering?.therapist?._id,
      };

      await this.updateTags(dto);

      console.log("testtt", userApi);

      const res = await userApi.getUsersMany(dto);

      const users = res.data;
      if (users.length) {
        users.reverse();
      }

      return users;
    },

    async showUsers() {
      this.loading = true;

      this.clientsForAdmin = await this.getUsers(this.actionsState);

      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.v-data-table::v-deep .v-data-table__wrapper {
  max-height: 600px !important;
}
::v-deep.v-data-table thead tr th {
  white-space: nowrap !important;
}

.border {
  border: solid;
}
</style>
