<template>
  <v-container fluid>
    <v-row class="fill-height">
      <v-col v-if="company" class="d-flex align-center" cols="12">
        <v-btn icon class="me-2" color="primary" small @click="$router.go(-1)"><v-icon small>mdi-arrow-left</v-icon></v-btn>

        <h2><small>Company:</small> {{ company.name }}</h2>
      </v-col>
      <v-col cols="12" class="text-end">
        <v-btn color="primary" @click="openCreateDialog" :disabled="loading">
          Create Promo Code
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          fixed-header
          :sort-by="['createdAt']"
          :sort-desc="true"
          :locale="$vuetify.rtl ? 'ar' : 'en'"
          :loading="loading"
          :headers="headers"
          :items="promoCodes"
          class="elevation-0 bg-transparent"
          calculate-widths
        >
          <template v-slot:item.actions="{ item }">
            <div class="mr-auto text-no-wrap">
              <v-icon small class="mr-2" @click="openEditDialog(item)">
                mdi-pencil
              </v-icon>
              <v-icon
                :disabled="!canDelete(item)"
                small
                @click="removeItem(item)"
              >
                mdi-delete
              </v-icon>
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <PromoCodeCreateEdit
      ref="promoCodeCreateEditRef"
      :corporatePlans="corporatePlans"
      @onSave="getCompanyPromoCodeData"
    />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import PromoCodeCreateEdit from "@/components/Admin/CorporateProgram/PromoCodeCreateEdit";

export default {
  name: "PromoCodes",

  components: {
    PromoCodeCreateEdit,
  },

  data: () => {
    return {
      loading: false,
      company: null,
      promoCodes: [],
      corporatePlans: [],

      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "_id",
          divider: true,
          width: 100,
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
          divider: true,
        },
        {
          text: "Description",
          align: "start",
          sortable: true,
          value: "description",
          divider: true,
        },
        {
          text: "Code",
          align: "start",
          sortable: true,
          value: "code",
          divider: true,
        },
        {
          text: "Domain",
          align: "start",
          sortable: true,
          value: "domain",
          divider: true,
        },
        {
          text: "Recurring Period Weekday",
          align: "start",
          sortable: true,
          value: "recurringPeriodWeekday",
          divider: true,
        },
        {
          text: "Start Date",
          align: "start",
          sortable: true,
          value: "startDate",
          divider: true,
        },
        {
          text: "Limit",
          align: "start",
          sortable: true,
          value: "limit",
          divider: true,
        },
        {
          text: "Corporate Plan",
          align: "start",
          sortable: true,
          value: "corporatePlan",
          divider: true,
        },
        {
          text: "Used",
          align: "start",
          sortable: true,
          value: "used",
          divider: true,
        },
        {
          text: "Active",
          align: "start",
          sortable: true,
          value: "active",
          divider: true,
        },
        {
          text: "Actions",
          align: "end",
          sortable: false,
          value: "actions",
          divider: true,
        },
      ],
    };
  },

  methods: {
    ...mapActions("corporateProgram", [
      "getCompany",
      "getPromoCode",
      "createPromoCode",
      "updatePromoCode",
      "removePromoCode",
    ]),

    ...mapActions("corporatePlan", ["getCorporatePlans"]),

    openEditDialog(item) {
      this.$refs.promoCodeCreateEditRef.open(this.company, item);
    },

    openCreateDialog() {
      this.$refs.promoCodeCreateEditRef.open(this.company);
    },

    canDelete(item) {
      return item.used === 0;
    },

    async removeItem(item) {
      await this.removePromoCode({ id: item._id });
      await this.getCompanyPromoCodeData();
    },

    async getCompanyPromoCodeData() {
      this.loading = true;

      this.company = await this.getCompany({
        id: this.$route.params.companyId,
      });
      this.corporatePlans = await this.getCorporatePlans();

      console.log("this.corporatePlans: ", this.corporatePlans);

      if (!this.company) {
        await this.$router.push({ name: "Companies" });
      }

      this.promoCodes = await this.getPromoCode({
        companyId: this.company._id,
      });
      this.loading = false;
    },
  },

  async mounted() {
    await this.getCompanyPromoCodeData();
  },
};
</script>

<style scoped></style>
