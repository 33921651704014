<template>
  <v-row>
    <v-col cols="auto">
      <v-btn color="primary" @click="dialog = !dialog">Create Therapist</v-btn>
    </v-col>
    <v-dialog v-model="dialog" max-width="500px" @close="dialog = false">
      <v-card class="rounded-lg">
        <v-btn @click="dialog = !dialog" elevation="2" icon class="close-icon pa-0">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-text class="px-8 pt-8">
          <v-form v-model="valid" ref="form">
            <v-text-field
                v-model="therapist.email"
                :rules="required"
                label="Email"
                type="email"
                dense
                outlined
                :loading="loading"
            >
            </v-text-field>
            <v-text-field
                v-model="therapist.password"
                :rules="required"
                label="Password"
                type="password"
                dense
                outlined
                :loading="loading"
            >
            </v-text-field>
          </v-form>

        </v-card-text>
        <v-card-actions class="pb-8">
          <v-spacer></v-spacer>
          <v-btn
              color="primary"
              @click="createTherapistAction"
              :loading="loading"
          >
            Create Therapist
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>
import {mapActions} from "vuex";

export default {
  data: () => {
    return {
      valid: false,
      dialog: false,
      therapist: {
        email: '',
        password: ''
      },
      loading: false,
      required: [(v) => !!v || "Required"],
    }
  },

  methods: {
    ...mapActions('therapist', ['createTherapist']),

    async createTherapistAction() {
      this.$refs.form.validate()
      if(this.valid) {
        this.loading = true;
        await this.createTherapist(this.therapist)
        this.loading = false;
        this.dialog = false;
      }
    }
  }
}
</script>
