<template>
  <v-container fluid>
    <v-row class="mb-2">
      <v-col md="6">
        <v-text-field
          v-model="search"
          label="Search by name, email or voucher"
          style="max-width: 340px"
          @input="delayedInputHandler"
          outlined
          solo
          flat
          dense
          hide-details
        >
          <v-icon slot="prepend-inner">mdi-magnify</v-icon>
        </v-text-field>
      </v-col>
      <v-col md="6" class="action-buttons">
        <v-btn
          color="primary"
          elevation="0"
          class="mx-2"
          @click="makeClientsCsv"
        >
          Export
          <span class="mx-2">|</span>
          <v-icon>mdi-export</v-icon>
        </v-btn>

        <v-dialog transition="dialog-top-transition" max-width="800" scrollable>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              elevation="0"
              class="mx-2"
              v-bind="attrs"
              v-on="on"
            >
              Add column
              <span class="mx-2">|</span>
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-card-text class="py-5">
                <h2>Customize Column</h2>
                <p>Add or Remove Columns</p>
                <div class="columns-list">
                  <div
                    class="column-item ma-2 pa-2"
                    v-for="item of columnsList"
                    :key="item.key"
                  >
                    <v-checkbox
                      v-model="item.isEnabled"
                      :label="item.label"
                      color="primary"
                      :value="item.isEnabled"
                      hide-details
                      class="ma-0 pa-0"
                      :ripple="false"
                    ></v-checkbox>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Close</v-btn>
                <v-btn
                  @click="
                    () => {
                      dialog.value = false;
                      emitChange('columns');
                    }
                  "
                  color="primary"
                >
                  Done
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>

        <v-menu
          ref="date-menu"
          v-model="dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation="0"
              depressed
              color="primary"
              v-bind="attrs"
              v-on="on"
              class="mx-2"
            >
              <v-icon small>mdi-calendar</v-icon>
              <span class="ml-2">{{ dateRangeText }}</span>
            </v-btn>
          </template>
          <v-date-picker v-model="dates" range no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="dates = []">Clear</v-btn>
            <v-btn text color="primary" @click="dateMenu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="dateChangeHandler">OK</v-btn>
          </v-date-picker>
        </v-menu>

        <v-menu
          ref="filters-menu"
          v-model="filtersMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
          left
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" class="mx-2" v-bind="attrs" v-on="on">
              <v-icon>mdi-tune-vertical</v-icon>
            </v-btn>
          </template>

          <v-card class="px-3 py-5">
            <v-select
              :items="filteringItems.disorders"
              v-model="filtering.disorder"
              return-object
              :item-text="(disorder) => disorder.name"
              label="Speciality / Diagnosis"
              solo
              dense
              flat
              outlined
            ></v-select>

            <v-select
              :items="filteringItems.speciality"
              label="Company Affiliation"
              solo
              dense
              flat
              outlined
            ></v-select>

            <v-select
              v-model="filtering.country"
              :items="filteringItems.countries"
              :item-text="getCountryItemName"
              label="Country"
              item-value="name"
              solo
              dense
              flat
              outlined
            ></v-select>

            <v-autocomplete
              v-model="filtering.therapist"
              :items="filteringItems.therapists"
              :item-text="
                (it) => `${it.profile.firstName} ${it.profile.lastName}`
              "
              label="Therapist"
              solo
              return-object
              dense
              flat
              outlined
            ></v-autocomplete>

            <v-select
              v-model="filtering.language"
              :items="filteringItems.languages"
              :item-text="(it) => it.label"
              return-object
              label="Languages"
              solo
              dense
              flat
              outlined
            ></v-select>

            <span class="caption">Gender</span>

            <v-radio-group v-model="filtering.gender" dense row class="mt-0">
              <v-radio label="Male" :ripple="false" value="male"></v-radio>
              <v-radio label="Female" :ripple="false" value="female"></v-radio>
            </v-radio-group>

            <div class="d-flex justify-center">
              <v-btn small class="mx-2" @click="clearFilters">
                Clear all
              </v-btn>
              <v-btn
                color="primary"
                small
                class="mx-2"
                @click="
                  () => {
                    emitChange('filtering');
                    filtersMenu = false;
                  }
                "
              >
                Done
              </v-btn>
            </div>
          </v-card>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-tabs v-model="selectedTag">
        <v-tab v-for="item of tags" :key="item.key" @change="onTagsChange">
          {{ item.label }}
          <span class="green--text ml-2">{{ item.count }}</span>
        </v-tab>
      </v-tabs>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import { mapActions } from "vuex";
import { countries } from "../../../../../constants/countries";
import { therapistApi, disorderApi } from "@/api";
import FileSaver from "file-saver";

export default {
  name: "TableActions",

  props: ["tags"],

  data: () => {
    return {
      search: "",
      dates: [],
      dateMenu: null,
      filtersMenu: null,
      initialFiltering: {
        disorder: null,
        companyAffiliation: null,
        country: null,
        language: null,
        gender: null,
      },
      filtering: {},
      filteringItems: {
        disorders: [],
        companyAffiliation: [],
        countries,
        therapistsList: [],
        languages: [
          {
            label: "English",
            code: "en",
          },
          {
            label: "Arabic",
            code: "ar",
          },
        ],
      },
      columnsList: [
        {
          label: "Total Spend",
          key: "totalSpend",
          isEnabled: true,
        },
        {
          label: "Subscription Plan",
          key: "SubscriptionType",
          isEnabled: true,
        },
        {
          label: "Subscription Sessions",
          key: "subscriptionSessions",
          isEnabled: true,
        },
        {
          label: "Subscription Start",
          key: "SubscriptionStartDate",
          isEnabled: true,
        },
        {
          label: "Subscription End",
          key: "subscriptionEnd",
          isEnabled: true,
        },
        {
          label: "Live Session Credit",
          key: "LiveSessionCredit",
          isEnabled: true,
        },
        {
          label: "Free Session Credit",
          key: "FreeLiveSessionCredit",
          isEnabled: true,
        },
        {
          label: "Next Session",
          key: "nextSession",
          isEnabled: true,
        },
        {
          label: "Last Session Date",
          key: "LastSessionDate",
          isEnabled: false,
        },
        {
          label: "Last Session Status",
          key: "LastSessionStatus",
          isEnabled: false,
        },
        {
          label: "Client Email",
          key: "email",
          isEnabled: false,
        },
        {
          label: "Client ID",
          key: "_id",
          isEnabled: false,
        },
        {
          label: "Therapist Name",
          key: "therapistName",
          isEnabled: false,
        },
        {
          label: "Therapist Email",
          key: "therapistEmail",
          isEnabled: false,
        },
        {
          label: "Company",
          key: "company",
          isEnabled: false,
        },
        {
          label: "Voucher",
          key: "voucher",
          isEnabled: false,
        },
      ],
      selectedTag: 0,
      timeoutId: null,
      tagsList: [],
    };
  },

  computed: {
    dateRangeText() {
      return `${moment(this.dates[0]).format("MMM")}-${moment(
        this.dates[1]
      ).format("MMM")}, ${moment(this.dates[1]).format("Y")}`;
    },
  },

  async created() {
    this.filtering = Object.assign({}, this.initialFiltering);

    therapistApi.getAllTherapists().then((res) => {
      this.filteringItems.therapists = res.data;
    });

    disorderApi.getAll().then((data) => {
      this.filteringItems.disorders = data.disorders;
    });

    this.emitChange();
  },

  methods: {
    ...mapActions("report", ["getClientReport"]),

    emitChange(action) {
      let tag = "all";
      if (this?.tags?.length > 0) tag = this.tags[this.selectedTag]?.key;

      this.$emit(
        "change",
        {
          search: this.search,
          columnsList: this.columnsList,
          dates: this.dates.map((d) => moment(d).format("YYYY-MM-DD")),
          filtering: this.filtering,
          tag,
        },
        {
          action,
        }
      );
    },

    dateChangeHandler() {
      if (this.dates[0] && !this.dates[1]) this.dates[1] = this.dates[0];

      console.log("dateeee", this.dates);
      this.emitChange("date");
      this.dateMenu = false;
    },

    clearFilters() {
      this.filtering = Object.assign({}, this.initialFiltering);
    },

    delayedInputHandler() {
      clearTimeout(this.timeoutId);

      this.timeoutId = setTimeout(() => {
        this.emitChange("search");
      }, 500);
    },

    onTagsChange() {
      setImmediate(() => {
        this.emitChange("tag");
      });
    },

    getCountryItemName(country) {
      return this.$t(country.name);
    },

    async makeClientsCsv() {
      this.loading = true;
      const data = await this.getClientReport();
      this.loading = false;
      const blob = new Blob([new Uint8Array(data.data)]);
      // const blob = new Blob(data, {type: "text/xlsx;charset=utf-8"});
      FileSaver.saveAs(blob, "talktime-client-report.xlsx");
    },
  },
};
</script>

<style scoped lang="scss">
.action-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.columns-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  background: #eff2f5;
  border-radius: 6px;
  padding: 25px;
  .column-item {
    width: calc(50% - 24px);
    background: #fff;
    border-radius: 6px;
  }
}
</style>
