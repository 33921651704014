/* eslint-disable */
const tmpCountries = [
  { name: "Afghanistan", code: "AF", id: 1 },
  { name: "Aland Islands", code: "AX", id: 2 },
  { name: "Albania", code: "AL", id: 3 },
  { name: "Algeria", code: "DZ", id: 4 },
  { name: "American Samoa", code: "AS", id: 5 },
  { name: "Andorra", code: "AD", id: 6 },
  { name: "Angola", code: "AO", id: 7 },
  { name: "Anguilla", code: "AI", id: 8 },
  { name: "Antarctica", code: "AQ", id: 9 },
  { name: "Antigua and Barbuda", code: "AG", id: 10 },
  { name: "Argentina", code: "AR", id: 11 },
  { name: "Armenia", code: "AM", id: 12 },
  { name: "Aruba", code: "AW", id: 13 },
  { name: "Australia", code: "AU", id: 14 },
  { name: "Austria", code: "AT", id: 15 },
  { name: "Azerbaijan", code: "AZ", id: 16 },
  { name: "Bahamas", code: "BS", id: 17 },
  { name: "Bahrain", code: "BH", id: 18 },
  { name: "Bangladesh", code: "BD", id: 19 },
  { name: "Barbados", code: "BB", id: 20 },
  { name: "Belarus", code: "BY", id: 21 },
  { name: "Belgium", code: "BE", id: 22 },
  { name: "Belize", code: "BZ", id: 23 },
  { name: "Benin", code: "BJ", id: 24 },
  { name: "Bermuda", code: "BM", id: 25 },
  { name: "Bhutan", code: "BT", id: 26 },
  { name: "Bolivia", code: "BO", id: 27 },
  { name: "Bonaire, Sint Eustatius and Saba", code: "BQ", id: 28 },
  { name: "Bosnia and Herzegovina", code: "BA", id: 29 },
  { name: "Botswana", code: "BW", id: 30 },
  { name: "Bouvet Island", code: "BV", id: 31 },
  { name: "Brazil", code: "BR", id: 32 },
  { name: "British Indian Ocean Territory", code: "IO", id: 33 },
  { name: "Brunei Darussalam", code: "BN", id: 34 },
  { name: "Bulgaria", code: "BG", id: 35 },
  { name: "Burkina Faso", code: "BF", id: 36 },
  { name: "Burundi", code: "BI", id: 37 },
  { name: "Cambodia", code: "KH", id: 38 },
  { name: "Cameroon", code: "CM", id: 39 },
  { name: "Canada", code: "CA", id: 40 },
  { name: "Cape Verde", code: "CV", id: 41 },
  { name: "Cayman Islands", code: "KY", id: 42 },
  { name: "Central African Republic", code: "CF", id: 43 },
  { name: "Chad", code: "TD", id: 44 },
  { name: "Chile", code: "CL", id: 45 },
  { name: "China", code: "CN", id: 46 },
  { name: "Christmas Island", code: "CX", id: 47 },
  { name: "Cocos (Keeling) Islands", code: "CC", id: 48 },
  { name: "Colombia", code: "CO", id: 49 },
  { name: "Comoros", code: "KM", id: 50 },
  { name: "Congo", code: "CG", id: 51 },
  { name: "Congo, Democratic Republic of the Congo", code: "CD", id: 52 },
  { name: "Cook Islands", code: "CK", id: 53 },
  { name: "Costa Rica", code: "CR", id: 54 },
  { name: "Cote D'Ivoire", code: "CI", id: 55 },
  { name: "Croatia", code: "HR", id: 56 },
  { name: "Cuba", code: "CU", id: 57 },
  { name: "Curacao", code: "CW", id: 58 },
  { name: "Cyprus", code: "CY", id: 59 },
  { name: "Czech Republic", code: "CZ", id: 60 },
  { name: "Denmark", code: "DK", id: 61 },
  { name: "Djibouti", code: "DJ", id: 62 },
  { name: "Dominica", code: "DM", id: 63 },
  { name: "Dominican Republic", code: "DO", id: 64 },
  { name: "Ecuador", code: "EC", id: 65 },
  { name: "Egypt", code: "EG", id: 66 },
  { name: "El Salvador", code: "SV", id: 67 },
  { name: "Equatorial Guinea", code: "GQ", id: 68 },
  { name: "Eritrea", code: "ER", id: 69 },
  { name: "Estonia", code: "EE", id: 70 },
  { name: "Ethiopia", code: "ET", id: 71 },
  { name: "Falkland Islands (Malvinas)", code: "FK", id: 72 },
  { name: "Faroe Islands", code: "FO", id: 73 },
  { name: "Fiji", code: "FJ", id: 74 },
  { name: "Finland", code: "FI", id: 75 },
  { name: "France", code: "FR", id: 76 },
  { name: "French Guiana", code: "GF", id: 77 },
  { name: "French Polynesia", code: "PF", id: 78 },
  { name: "French Southern Territories", code: "TF", id: 79 },
  { name: "Gabon", code: "GA", id: 80 },
  { name: "Gambia", code: "GM", id: 81 },
  { name: "Georgia", code: "GE", id: 82 },
  { name: "Germany", code: "DE", id: 83 },
  { name: "Ghana", code: "GH", id: 84 },
  { name: "Gibraltar", code: "GI", id: 85 },
  { name: "Greece", code: "GR", id: 86 },
  { name: "Greenland", code: "GL", id: 87 },
  { name: "Grenada", code: "GD", id: 88 },
  { name: "Guadeloupe", code: "GP", id: 89 },
  { name: "Guam", code: "GU", id: 90 },
  { name: "Guatemala", code: "GT", id: 91 },
  { name: "Guernsey", code: "GG", id: 92 },
  { name: "Guinea", code: "GN", id: 93 },
  { name: "Guinea-Bissau", code: "GW", id: 94 },
  { name: "Guyana", code: "GY", id: 95 },
  { name: "Haiti", code: "HT", id: 96 },
  { name: "Heard Island and Mcdonald Islands", code: "HM", id: 97 },
  { name: "Holy See (Vatican City State)", code: "VA", id: 98 },
  { name: "Honduras", code: "HN", id: 99 },
  { name: "Hong Kong", code: "HK", id: 100 },
  { name: "Hungary", code: "HU", id: 101 },
  { name: "Iceland", code: "IS", id: 102 },
  { name: "India", code: "IN", id: 103 },
  { name: "Indonesia", code: "ID", id: 104 },
  { name: "Iran, Islamic Republic of", code: "IR", id: 105 },
  { name: "Iraq", code: "IQ", id: 106 },
  { name: "Ireland", code: "IE", id: 107 },
  { name: "Isle of Man", code: "IM", id: 108 },
  { name: "Israel", code: "IL", id: 109 },
  { name: "Italy", code: "IT", id: 110 },
  { name: "Jamaica", code: "JM", id: 111 },
  { name: "Japan", code: "JP", id: 112 },
  { name: "Jersey", code: "JE", id: 113 },
  { name: "Jordan", code: "JO", id: 114 },
  { name: "Kazakhstan", code: "KZ", id: 115 },
  { name: "Kenya", code: "KE", id: 116 },
  { name: "Kiribati", code: "KI", id: 117 },
  { name: "Korea, Democratic People's Republic of", code: "KP", id: 118 },
  { name: "Korea, Republic of", code: "KR", id: 119 },
  { name: "Kosovo", code: "XK", id: 120 },
  { name: "Kuwait", code: "KW", id: 121 },
  { name: "Kyrgyzstan", code: "KG", id: 122 },
  { name: "Lao People's Democratic Republic", code: "LA", id: 123 },
  { name: "Latvia", code: "LV", id: 124 },
  { name: "Lebanon", code: "LB", id: 125 },
  { name: "Lesotho", code: "LS", id: 126 },
  { name: "Liberia", code: "LR", id: 127 },
  { name: "Libyan Arab Jamahiriya", code: "LY", id: 128 },
  { name: "Liechtenstein", code: "LI", id: 129 },
  { name: "Lithuania", code: "LT", id: 130 },
  { name: "Luxembourg", code: "LU", id: 131 },
  { name: "Macao", code: "MO", id: 132 },
  { name: "Macedonia, the Former Yugoslav Republic of", code: "MK", id: 133 },
  { name: "Madagascar", code: "MG", id: 134 },
  { name: "Malawi", code: "MW", id: 135 },
  { name: "Malaysia", code: "MY", id: 136 },
  { name: "Maldives", code: "MV", id: 137 },
  { name: "Mali", code: "ML", id: 138 },
  { name: "Malta", code: "MT", id: 139 },
  { name: "Marshall Islands", code: "MH", id: 140 },
  { name: "Martinique", code: "MQ", id: 141 },
  { name: "Mauritania", code: "MR", id: 142 },
  { name: "Mauritius", code: "MU", id: 143 },
  { name: "Mayotte", code: "YT", id: 144 },
  { name: "Mexico", code: "MX", id: 145 },
  { name: "Micronesia, Federated States of", code: "FM", id: 146 },
  { name: "Moldova, Republic of", code: "MD", id: 147 },
  { name: "Monaco", code: "MC", id: 148 },
  { name: "Mongolia", code: "MN", id: 149 },
  { name: "Montenegro", code: "ME", id: 150 },
  { name: "Montserrat", code: "MS", id: 151 },
  { name: "Morocco", code: "MA", id: 152 },
  { name: "Mozambique", code: "MZ", id: 153 },
  { name: "Myanmar", code: "MM", id: 154 },
  { name: "Namibia", code: "NA", id: 155 },
  { name: "Nauru", code: "NR", id: 156 },
  { name: "Nepal", code: "NP", id: 157 },
  { name: "Netherlands", code: "NL", id: 158 },
  { name: "Netherlands Antilles", code: "AN", id: 159 },
  { name: "New Caledonia", code: "NC", id: 160 },
  { name: "New Zealand", code: "NZ", id: 161 },
  { name: "Nicaragua", code: "NI", id: 162 },
  { name: "Niger", code: "NE", id: 163 },
  { name: "Nigeria", code: "NG", id: 164 },
  { name: "Niue", code: "NU", id: 165 },
  { name: "Norfolk Island", code: "NF", id: 166 },
  { name: "Northern Mariana Islands", code: "MP", id: 167 },
  { name: "Norway", code: "NO", id: 168 },
  { name: "Oman", code: "OM", id: 169 },
  { name: "Pakistan", code: "PK", id: 170 },
  { name: "Palau", code: "PW", id: 171 },
  { name: "Palestinian Territory, Occupied", code: "PS", id: 172 },
  { name: "Panama", code: "PA", id: 173 },
  { name: "Papua New Guinea", code: "PG", id: 174 },
  { name: "Paraguay", code: "PY", id: 175 },
  { name: "Peru", code: "PE", id: 176 },
  { name: "Philippines", code: "PH", id: 177 },
  { name: "Pitcairn", code: "PN", id: 178 },
  { name: "Poland", code: "PL", id: 179 },
  { name: "Portugal", code: "PT", id: 180 },
  { name: "Puerto Rico", code: "PR", id: 181 },
  { name: "Qatar", code: "QA", id: 182 },
  { name: "Reunion", code: "RE", id: 183 },
  { name: "Romania", code: "RO", id: 184 },
  { name: "Russian Federation", code: "RU", id: 185 },
  { name: "Rwanda", code: "RW", id: 186 },
  { name: "Saint Barthelemy", code: "BL", id: 187 },
  { name: "Saint Helena", code: "SH", id: 188 },
  { name: "Saint Kitts and Nevis", code: "KN", id: 189 },
  { name: "Saint Lucia", code: "LC", id: 190 },
  { name: "Saint Martin", code: "MF", id: 191 },
  { name: "Saint Pierre and Miquelon", code: "PM", id: 192 },
  { name: "Saint Vincent and the Grenadines", code: "VC", id: 193 },
  { name: "Samoa", code: "WS", id: 194 },
  { name: "San Marino", code: "SM", id: 195 },
  { name: "Sao Tome and Principe", code: "ST", id: 196 },
  { name: "Saudi Arabia", code: "SA", id: 197 },
  { name: "Senegal", code: "SN", id: 198 },
  { name: "Serbia", code: "RS", id: 199 },
  { name: "Serbia and Montenegro", code: "CS", id: 200 },
  { name: "Seychelles", code: "SC", id: 201 },
  { name: "Sierra Leone", code: "SL", id: 202 },
  { name: "Singapore", code: "SG", id: 203 },
  { name: "Sint Maarten", code: "SX", id: 204 },
  { name: "Slovakia", code: "SK", id: 205 },
  { name: "Slovenia", code: "SI", id: 206 },
  { name: "Solomon Islands", code: "SB", id: 207 },
  { name: "Somalia", code: "SO", id: 208 },
  { name: "South Africa", code: "ZA", id: 209 },
  { name: "South Georgia and the South Sandwich Islands", code: "GS", id: 210 },
  { name: "South Sudan", code: "SS", id: 211 },
  { name: "Spain", code: "ES", id: 212 },
  { name: "Sri Lanka", code: "LK", id: 213 },
  { name: "Sudan", code: "SD", id: 214 },
  { name: "Suriname", code: "SR", id: 215 },
  { name: "Svalbard and Jan Mayen", code: "SJ", id: 216 },
  { name: "Swaziland", code: "SZ", id: 217 },
  { name: "Sweden", code: "SE", id: 218 },
  { name: "Switzerland", code: "CH", id: 219 },
  { name: "Syrian Arab Republic", code: "SY", id: 220 },
  { name: "Taiwan, Province of China", code: "TW", id: 221 },
  { name: "Tajikistan", code: "TJ", id: 222 },
  { name: "Tanzania, United Republic of", code: "TZ", id: 223 },
  { name: "Thailand", code: "TH", id: 224 },
  { name: "Timor-Leste", code: "TL", id: 225 },
  { name: "Togo", code: "TG", id: 226 },
  { name: "Tokelau", code: "TK", id: 227 },
  { name: "Tonga", code: "TO", id: 228 },
  { name: "Trinidad and Tobago", code: "TT", id: 229 },
  { name: "Tunisia", code: "TN", id: 230 },
  { name: "Turkey", code: "TR", id: 231 },
  { name: "Turkmenistan", code: "TM", id: 232 },
  { name: "Turks and Caicos Islands", code: "TC", id: 233 },
  { name: "Tuvalu", code: "TV", id: 234 },
  { name: "Uganda", code: "UG", id: 235 },
  { name: "Ukraine", code: "UA", id: 236 },
  { name: "United Arab Emirates", code: "AE", id: 237 },
  { name: "United Kingdom", code: "GB", id: 238 },
  { name: "United States", code: "US", id: 239 },
  { name: "United States Minor Outlying Islands", code: "UM", id: 240 },
  { name: "Uruguay", code: "UY", id: 241 },
  { name: "Uzbekistan", code: "UZ", id: 242 },
  { name: "Vanuatu", code: "VU", id: 243 },
  { name: "Venezuela", code: "VE", id: 244 },
  { name: "Viet Nam", code: "VN", id: 245 },
  { name: "Virgin Islands, British", code: "VG", id: 246 },
  { name: "Virgin Islands, U.s.", code: "VI", id: 247 },
  { name: "Wallis and Futuna", code: "WF", id: 248 },
  { name: "Western Sahara", code: "EH", id: 249 },
  { name: "Yemen", code: "YE", id: 250 },
  { name: "Zambia", code: "ZM", id: 251 },
  { name: "Zimbabwe", code: "ZW", id: 252 },
];

const tmp = [
  {
    name: "Afghanistan",
    code: "AF",
    id: 1,
    nameAr: "أفغانستان",
  },
  {
    name: "Aland Islands",
    code: "AX",
    id: 2,
    nameAr: null,
  },
  {
    name: "Albania",
    code: "AL",
    id: 3,
    nameAr: "ألبانيا",
  },
  {
    name: "Algeria",
    code: "DZ",
    id: 4,
    nameAr: "الجزائر",
  },
  {
    name: "American Samoa",
    code: "AS",
    id: 5,
    nameAr: "ساموا-الأمريكي",
  },
  {
    name: "Andorra",
    code: "AD",
    id: 6,
    nameAr: "أندورا",
  },
  {
    name: "Angola",
    code: "AO",
    id: 7,
    nameAr: "أنجولا",
  },
  {
    name: "Anguilla",
    code: "AI",
    id: 8,
    nameAr: "أنجويلا",
  },
  {
    name: "Antarctica",
    code: "AQ",
    id: 9,
    nameAr: "المنطقة القطبية الجنوبية",
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    id: 10,
    nameAr: "أنتيغوا وباربودا",
  },
  {
    name: "Argentina",
    code: "AR",
    id: 11,
    nameAr: "الأرجنتين",
  },
  {
    name: "Armenia",
    code: "AM",
    id: 12,
    nameAr: "أرمينيا",
  },
  {
    name: "Aruba",
    code: "AW",
    id: 13,
    nameAr: null,
  },
  {
    name: "Australia",
    code: "AU",
    id: 14,
    nameAr: "أستراليا",
  },
  {
    name: "Austria",
    code: "AT",
    id: 15,
    nameAr: "النمسا",
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    id: 16,
    nameAr: "أذربيجان",
  },
  {
    name: "Bahamas",
    code: "BS",
    id: 17,
    nameAr: "باهاماس",
  },
  {
    name: "Bahrain",
    code: "BH",
    id: 18,
    nameAr: "البحرين",
  },
  {
    name: "Bangladesh",
    code: "BD",
    id: 19,
    nameAr: "بنجلاديش",
  },
  {
    name: "Barbados",
    code: "BB",
    id: 20,
    nameAr: "باربادوس",
  },
  {
    name: "Belarus",
    code: "BY",
    id: 21,
    nameAr: "بيلاروس",
  },
  {
    name: "Belgium",
    code: "BE",
    id: 22,
    nameAr: "بلجيكا",
  },
  {
    name: "Belize",
    code: "BZ",
    id: 23,
    nameAr: "بيليز",
  },
  {
    name: "Benin",
    code: "BJ",
    id: 24,
    nameAr: "بنين",
  },
  {
    name: "Bermuda",
    code: "BM",
    id: 25,
    nameAr: "برمودا",
  },
  {
    name: "Bhutan",
    code: "BT",
    id: 26,
    nameAr: "بوتان",
  },
  {
    name: "Bolivia",
    code: "BO",
    id: 27,
    nameAr: "بوليفيا",
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    code: "BQ",
    id: 28,
    nameAr: null,
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    id: 29,
    nameAr: "البوسنة والهرسك",
  },
  {
    name: "Botswana",
    code: "BW",
    id: 30,
    nameAr: "بوتسوانا",
  },
  {
    name: "Bouvet Island",
    code: "BV",
    id: 31,
    nameAr: null,
  },
  {
    name: "Brazil",
    code: "BR",
    id: 32,
    nameAr: "البرازيل",
  },
  {
    name: "British Indian Ocean Territory",
    code: "IO",
    id: 33,
    nameAr: null,
  },
  {
    name: "Brunei Darussalam",
    code: "BN",
    id: 34,
    nameAr: null,
  },
  {
    name: "Bulgaria",
    code: "BG",
    id: 35,
    nameAr: "بلغاريا",
  },
  {
    name: "Burkina Faso",
    code: "BF",
    id: 36,
    nameAr: "بوركينا فاسو",
  },
  {
    name: "Burundi",
    code: "BI",
    id: 37,
    nameAr: "بوروندي",
  },
  {
    name: "Cambodia",
    code: "KH",
    id: 38,
    nameAr: "كمبوديا",
  },
  {
    name: "Cameroon",
    code: "CM",
    id: 39,
    nameAr: "كاميرون",
  },
  {
    name: "Canada",
    code: "CA",
    id: 40,
    nameAr: "كندا",
  },
  {
    name: "Cape Verde",
    code: "CV",
    id: 41,
    nameAr: "الرأس الأخضر",
  },
  {
    name: "Cayman Islands",
    code: "KY",
    id: 42,
    nameAr: "جزر كايمان",
  },
  {
    name: "Central African Republic",
    code: "CF",
    id: 43,
    nameAr: "جمهورية أفريقيا الوسطى",
  },
  {
    name: "Chad",
    code: "TD",
    id: 44,
    nameAr: "تشاد",
  },
  {
    name: "Chile",
    code: "CL",
    id: 45,
    nameAr: "تشيلي",
  },
  {
    name: "China",
    code: "CN",
    id: 46,
    nameAr: "الصين",
  },
  {
    name: "Christmas Island",
    code: "CX",
    id: 47,
    nameAr: null,
  },
  {
    name: "Cocos (Keeling) Islands",
    code: "CC",
    id: 48,
    nameAr: null,
  },
  {
    name: "Colombia",
    code: "CO",
    id: 49,
    nameAr: "كولومبيا",
  },
  {
    name: "Comoros",
    code: "KM",
    id: 50,
    nameAr: "جزر القمر",
  },
  {
    name: "Congo",
    code: "CG",
    id: 51,
    nameAr: null,
  },
  {
    name: "Congo, Democratic Republic of the Congo",
    code: "CD",
    id: 52,
    nameAr: null,
  },
  {
    name: "Cook Islands",
    code: "CK",
    id: 53,
    nameAr: "جزر كوك",
  },
  {
    name: "Costa Rica",
    code: "CR",
    id: 54,
    nameAr: "كوستاريكا",
  },
  {
    name: "Cote D'Ivoire",
    code: "CI",
    id: 55,
    nameAr: null,
  },
  {
    name: "Croatia",
    code: "HR",
    id: 56,
    nameAr: "كرواتيا",
  },
  {
    name: "Cuba",
    code: "CU",
    id: 57,
    nameAr: "كوبا",
  },
  {
    name: "Curacao",
    code: "CW",
    id: 58,
    nameAr: null,
  },
  {
    name: "Cyprus",
    code: "CY",
    id: 59,
    nameAr: "قبرص",
  },
  {
    name: "Czech Republic",
    code: "CZ",
    id: 60,
    nameAr: null,
  },
  {
    name: "Denmark",
    code: "DK",
    id: 61,
    nameAr: "الدنمارك",
  },
  {
    name: "Djibouti",
    code: "DJ",
    id: 62,
    nameAr: "جيبوتي",
  },
  {
    name: "Dominica",
    code: "DM",
    id: 63,
    nameAr: "دومينيكا",
  },
  {
    name: "Dominican Republic",
    code: "DO",
    id: 64,
    nameAr: "جمهورية الدومينيكان",
  },
  {
    name: "Ecuador",
    code: "EC",
    id: 65,
    nameAr: "الإكوادور",
  },
  {
    name: "Egypt",
    code: "EG",
    id: 66,
    nameAr: "مصر",
  },
  {
    name: "El Salvador",
    code: "SV",
    id: 67,
    nameAr: "السلفادور",
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    id: 68,
    nameAr: "غينيا الاستوائية",
  },
  {
    name: "Eritrea",
    code: "ER",
    id: 69,
    nameAr: "إريتريا",
  },
  {
    name: "Estonia",
    code: "EE",
    id: 70,
    nameAr: "إستونيا",
  },
  {
    name: "Ethiopia",
    code: "ET",
    id: 71,
    nameAr: "إثيوبيا",
  },
  {
    name: "Falkland Islands (Malvinas)",
    code: "FK",
    id: 72,
    nameAr: null,
  },
  {
    name: "Faroe Islands",
    code: "FO",
    id: 73,
    nameAr: "جزر فارو",
  },
  {
    name: "Fiji",
    code: "FJ",
    id: 74,
    nameAr: "فيجي",
  },
  {
    name: "Finland",
    code: "FI",
    id: 75,
    nameAr: "فنلندا",
  },
  {
    name: "France",
    code: "FR",
    id: 76,
    nameAr: "فرنسا",
  },
  {
    name: "French Guiana",
    code: "GF",
    id: 77,
    nameAr: "غينيا الفرنسية",
  },
  {
    name: "French Polynesia",
    code: "PF",
    id: 78,
    nameAr: "بولينيزيا الفرنسية",
  },
  {
    name: "French Southern Territories",
    code: "TF",
    id: 79,
    nameAr: null,
  },
  {
    name: "Gabon",
    code: "GA",
    id: 80,
    nameAr: "الغابون",
  },
  {
    name: "Gambia",
    code: "GM",
    id: 81,
    nameAr: "غامبيا",
  },
  {
    name: "Georgia",
    code: "GE",
    id: 82,
    nameAr: "جورجيا",
  },
  {
    name: "Germany",
    code: "DE",
    id: 83,
    nameAr: "ألمانيا",
  },
  {
    name: "Ghana",
    code: "GH",
    id: 84,
    nameAr: "غانا",
  },
  {
    name: "Gibraltar",
    code: "GI",
    id: 85,
    nameAr: null,
  },
  {
    name: "Greece",
    code: "GR",
    id: 86,
    nameAr: "اليونان",
  },
  {
    name: "Greenland",
    code: "GL",
    id: 87,
    nameAr: "جرينلاند",
  },
  {
    name: "Grenada",
    code: "GD",
    id: 88,
    nameAr: "غرينادا",
  },
  {
    name: "Guadeloupe",
    code: "GP",
    id: 89,
    nameAr: "غوادلوب",
  },
  {
    name: "Guam",
    code: "GU",
    id: 90,
    nameAr: "غوام",
  },
  {
    name: "Guatemala",
    code: "GT",
    id: 91,
    nameAr: "غواتيمال",
  },
  {
    name: "Guernsey",
    code: "GG",
    id: 92,
    nameAr: null,
  },
  {
    name: "Guinea",
    code: "GN",
    id: 93,
    nameAr: "غينيا",
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    id: 94,
    nameAr: "غينيا بيساو",
  },
  {
    name: "Guyana",
    code: "GY",
    id: 95,
    nameAr: "غيانا",
  },
  {
    name: "Haiti",
    code: "HT",
    id: 96,
    nameAr: "هايتي",
  },
  {
    name: "Heard Island and Mcdonald Islands",
    code: "HM",
    id: 97,
    nameAr: null,
  },
  {
    name: "Holy See (Vatican City State)",
    code: "VA",
    id: 98,
    nameAr: null,
  },
  {
    name: "Honduras",
    code: "HN",
    id: 99,
    nameAr: "هندوراس",
  },
  {
    name: "Hong Kong",
    code: "HK",
    id: 100,
    nameAr: "هونغ كونغ",
  },
  {
    name: "Hungary",
    code: "HU",
    id: 101,
    nameAr: "المجر",
  },
  {
    name: "Iceland",
    code: "IS",
    id: 102,
    nameAr: "أيسلندا",
  },
  {
    name: "India",
    code: "IN",
    id: 103,
    nameAr: "الهند",
  },
  {
    name: "Indonesia",
    code: "ID",
    id: 104,
    nameAr: "إندونيسيا",
  },
  {
    name: "Iran, Islamic Republic of",
    code: "IR",
    id: 105,
    nameAr: null,
  },
  {
    name: "Iraq",
    code: "IQ",
    id: 106,
    nameAr: "العراق",
  },
  {
    name: "Ireland",
    code: "IE",
    id: 107,
    nameAr: "جمهورية أيرلندا",
  },
  {
    name: "Isle of Man",
    code: "IM",
    id: 108,
    nameAr: null,
  },
  {
    name: "Israel",
    code: "IL",
    id: 109,
    nameAr: null,
  },
  {
    name: "Italy",
    code: "IT",
    id: 110,
    nameAr: "إيطاليا",
  },
  {
    name: "Jamaica",
    code: "JM",
    id: 111,
    nameAr: "جامايكا",
  },
  {
    name: "Japan",
    code: "JP",
    id: 112,
    nameAr: "اليابان",
  },
  {
    name: "Jersey",
    code: "JE",
    id: 113,
    nameAr: null,
  },
  {
    name: "Jordan",
    code: "JO",
    id: 114,
    nameAr: "الأردن",
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    id: 115,
    nameAr: "كازاخستان",
  },
  {
    name: "Kenya",
    code: "KE",
    id: 116,
    nameAr: "كينيا",
  },
  {
    name: "Kiribati",
    code: "KI",
    id: 117,
    nameAr: "كيريباتي",
  },
  {
    name: "Korea, Democratic People's Republic of",
    code: "KP",
    id: 118,
    nameAr: null,
  },
  {
    name: "Korea, Republic of",
    code: "KR",
    id: 119,
    nameAr: null,
  },
  {
    name: "Kosovo",
    code: "XK",
    id: 120,
    nameAr: null,
  },
  {
    name: "Kuwait",
    code: "KW",
    id: 121,
    nameAr: "الكويت",
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    id: 122,
    nameAr: "قيرغيزستان",
  },
  {
    name: "Lao People's Democratic Republic",
    code: "LA",
    id: 123,
    nameAr: null,
  },
  {
    name: "Latvia",
    code: "LV",
    id: 124,
    nameAr: "لاتفيا",
  },
  {
    name: "Lebanon",
    code: "LB",
    id: 125,
    nameAr: "لبنان",
  },
  {
    name: "Lesotho",
    code: "LS",
    id: 126,
    nameAr: "ليسوتو",
  },
  {
    name: "Liberia",
    code: "LR",
    id: 127,
    nameAr: "ليبيريا",
  },
  {
    name: "Libyan Arab Jamahiriya",
    code: "LY",
    id: 128,
    nameAr: null,
  },
  {
    name: "Liechtenstein",
    code: "LI",
    id: 129,
    nameAr: "ليختنشتاين",
  },
  {
    name: "Lithuania",
    code: "LT",
    id: 130,
    nameAr: "ليتوانيا",
  },
  {
    name: "Luxembourg",
    code: "LU",
    id: 131,
    nameAr: "لوكسمبورغ",
  },
  {
    name: "Macao",
    code: "MO",
    id: 132,
    nameAr: null,
  },
  {
    name: "Macedonia, the Former Yugoslav Republic of",
    code: "MK",
    id: 133,
    nameAr: null,
  },
  {
    name: "Madagascar",
    code: "MG",
    id: 134,
    nameAr: "مدغشقر",
  },
  {
    name: "Malawi",
    code: "MW",
    id: 135,
    nameAr: "مالاوي",
  },
  {
    name: "Malaysia",
    code: "MY",
    id: 136,
    nameAr: "ماليزيا",
  },
  {
    name: "Maldives",
    code: "MV",
    id: 137,
    nameAr: "المالديف",
  },
  {
    name: "Mali",
    code: "ML",
    id: 138,
    nameAr: "مالي",
  },
  {
    name: "Malta",
    code: "MT",
    id: 139,
    nameAr: "مالطا",
  },
  {
    name: "Marshall Islands",
    code: "MH",
    id: 140,
    nameAr: "جزر مارشال",
  },
  {
    name: "Martinique",
    code: "MQ",
    id: 141,
    nameAr: "مارتينيك",
  },
  {
    name: "Mauritania",
    code: "MR",
    id: 142,
    nameAr: "موريتانيا",
  },
  {
    name: "Mauritius",
    code: "MU",
    id: 143,
    nameAr: "موريشيوس",
  },
  {
    name: "Mayotte",
    code: "YT",
    id: 144,
    nameAr: "مايوت",
  },
  {
    name: "Mexico",
    code: "MX",
    id: 145,
    nameAr: "المكسيك",
  },
  {
    name: "Micronesia, Federated States of",
    code: "FM",
    id: 146,
    nameAr: null,
  },
  {
    name: "Moldova, Republic of",
    code: "MD",
    id: 147,
    nameAr: null,
  },
  {
    name: "Monaco",
    code: "MC",
    id: 148,
    nameAr: "موناكو",
  },
  {
    name: "Mongolia",
    code: "MN",
    id: 149,
    nameAr: "منغوليا",
  },
  {
    name: "Montenegro",
    code: "ME",
    id: 150,
    nameAr: "الجبل الأسو",
  },
  {
    name: "Montserrat",
    code: "MS",
    id: 151,
    nameAr: "مونتسرات",
  },
  {
    name: "Morocco",
    code: "MA",
    id: 152,
    nameAr: "المغرب",
  },
  {
    name: "Mozambique",
    code: "MZ",
    id: 153,
    nameAr: "موزمبيق",
  },
  {
    name: "Myanmar",
    code: "MM",
    id: 154,
    nameAr: " ميانمار",
  },
  {
    name: "Namibia",
    code: "NA",
    id: 155,
    nameAr: "ناميبيا",
  },
  {
    name: "Nauru",
    code: "NR",
    id: 156,
    nameAr: "ناورو",
  },
  {
    name: "Nepal",
    code: "NP",
    id: 157,
    nameAr: "نيبال",
  },
  {
    name: "Netherlands",
    code: "NL",
    id: 158,
    nameAr: "هولندا",
  },
  {
    name: "Netherlands Antilles",
    code: "AN",
    id: 159,
    nameAr: null,
  },
  {
    name: "New Caledonia",
    code: "NC",
    id: 160,
    nameAr: null,
  },
  {
    name: "New Zealand",
    code: "NZ",
    id: 161,
    nameAr: "نيوزيلندا",
  },
  {
    name: "Nicaragua",
    code: "NI",
    id: 162,
    nameAr: "نيكاراجوا",
  },
  {
    name: "Niger",
    code: "NE",
    id: 163,
    nameAr: "النيجر",
  },
  {
    name: "Nigeria",
    code: "NG",
    id: 164,
    nameAr: "نيجيريا",
  },
  {
    name: "Niue",
    code: "NU",
    id: 165,
    nameAr: null,
  },
  {
    name: "Norfolk Island",
    code: "NF",
    id: 166,
    nameAr: null,
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    id: 167,
    nameAr: "جزر ماريانا الشمالية",
  },
  {
    name: "Norway",
    code: "NO",
    id: 168,
    nameAr: "النرويج",
  },
  {
    name: "Oman",
    code: "OM",
    id: 169,
    nameAr: "عُمان",
  },
  {
    name: "Pakistan",
    code: "PK",
    id: 170,
    nameAr: "باكستان",
  },
  {
    name: "Palau",
    code: "PW",
    id: 171,
    nameAr: "بالاو",
  },
  {
    name: "Palestinian Territory, Occupied",
    code: "PS",
    id: 172,
    nameAr: null,
  },
  {
    name: "Panama",
    code: "PA",
    id: 173,
    nameAr: "بنما",
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    id: 174,
    nameAr: "بابوا غينيا الجديدة",
  },
  {
    name: "Paraguay",
    code: "PY",
    id: 175,
    nameAr: "باراغواي",
  },
  {
    name: "Peru",
    code: "PE",
    id: 176,
    nameAr: "بيرو",
  },
  {
    name: "Philippines",
    code: "PH",
    id: 177,
    nameAr: "الفلبين",
  },
  {
    name: "Pitcairn",
    code: "PN",
    id: 178,
    nameAr: null,
  },
  {
    name: "Poland",
    code: "PL",
    id: 179,
    nameAr: "بولندا",
  },
  {
    name: "Portugal",
    code: "PT",
    id: 180,
    nameAr: "البرتغال",
  },
  {
    name: "Puerto Rico",
    code: "PR",
    id: 181,
    nameAr: "بورتوريكو",
  },
  {
    name: "Qatar",
    code: "QA",
    id: 182,
    nameAr: "قطر",
  },
  {
    name: "Reunion",
    code: "RE",
    id: 183,
    nameAr: null,
  },
  {
    name: "Romania",
    code: "RO",
    id: 184,
    nameAr: "رومانيا",
  },
  {
    name: "Russian Federation",
    code: "RU",
    id: 185,
    nameAr: null,
  },
  {
    name: "Rwanda",
    code: "RW",
    id: 186,
    nameAr: "رواندا",
  },
  {
    name: "Saint Barthelemy",
    code: "BL",
    id: 187,
    nameAr: null,
  },
  {
    name: "Saint Helena",
    code: "SH",
    id: 188,
    nameAr: null,
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    id: 189,
    nameAr: null,
  },
  {
    name: "Saint Lucia",
    code: "LC",
    id: 190,
    nameAr: null,
  },
  {
    name: "Saint Martin",
    code: "MF",
    id: 191,
    nameAr: null,
  },
  {
    name: "Saint Pierre and Miquelon",
    code: "PM",
    id: 192,
    nameAr: null,
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    id: 193,
    nameAr: null,
  },
  {
    name: "Samoa",
    code: "WS",
    id: 194,
    nameAr: null,
  },
  {
    name: "San Marino",
    code: "SM",
    id: 195,
    nameAr: null,
  },
  {
    name: "Sao Tome and Principe",
    code: "ST",
    id: 196,
    nameAr: null,
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    id: 197,
    nameAr: "السعودية",
  },
  {
    name: "Senegal",
    code: "SN",
    id: 198,
    nameAr: "السنغال",
  },
  {
    name: "Serbia",
    code: "RS",
    id: 199,
    nameAr: "صربيا",
  },
  {
    name: "Serbia and Montenegro",
    code: "CS",
    id: 200,
    nameAr: null,
  },
  {
    name: "Seychelles",
    code: "SC",
    id: 201,
    nameAr: "سيشل",
  },
  {
    name: "Sierra Leone",
    code: "SL",
    id: 202,
    nameAr: "سيراليون",
  },
  {
    name: "Singapore",
    code: "SG",
    id: 203,
    nameAr: "سنغافورة",
  },
  {
    name: "Sint Maarten",
    code: "SX",
    id: 204,
    nameAr: null,
  },
  {
    name: "Slovakia",
    code: "SK",
    id: 205,
    nameAr: "سلوفاكيا",
  },
  {
    name: "Slovenia",
    code: "SI",
    id: 206,
    nameAr: "سلوفينيا",
  },
  {
    name: "Solomon Islands",
    code: "SB",
    id: 207,
    nameAr: null,
  },
  {
    name: "Somalia",
    code: "SO",
    id: 208,
    nameAr: "الصومال",
  },
  {
    name: "South Africa",
    code: "ZA",
    id: 209,
    nameAr: "جنوب أفريقيا",
  },
  {
    name: "South Georgia and the South Sandwich Islands",
    code: "GS",
    id: 210,
    nameAr: null,
  },
  {
    name: "South Sudan",
    code: "SS",
    id: 211,
    nameAr: "جنوب السودان",
  },
  {
    name: "Spain",
    code: "ES",
    id: 212,
    nameAr: "إسبانيا",
  },
  {
    name: "Sri Lanka",
    code: "LK",
    id: 213,
    nameAr: "سريلانكا",
  },
  {
    name: "Sudan",
    code: "SD",
    id: 214,
    nameAr: "السودان",
  },
  {
    name: "Suriname",
    code: "SR",
    id: 215,
    nameAr: "سورينام",
  },
  {
    name: "Svalbard and Jan Mayen",
    code: "SJ",
    id: 216,
    nameAr: null,
  },
  {
    name: "Swaziland",
    code: "SZ",
    id: 217,
    nameAr: "سوازيلند",
  },
  {
    name: "Sweden",
    code: "SE",
    id: 218,
    nameAr: "السويد",
  },
  {
    name: "Switzerland",
    code: "CH",
    id: 219,
    nameAr: "سويسرا",
  },
  {
    name: "Syrian Arab Republic",
    code: "SY",
    id: 220,
    nameAr: null,
  },
  {
    name: "Taiwan, Province of China",
    code: "TW",
    id: 221,
    nameAr: null,
  },
  {
    name: "Tajikistan",
    code: "TJ",
    id: 222,
    nameAr: "طاجيكستان",
  },
  {
    name: "Tanzania, United Republic of",
    code: "TZ",
    id: 223,
    nameAr: null,
  },
  {
    name: "Thailand",
    code: "TH",
    id: 224,
    nameAr: "تايلاند",
  },
  {
    name: "Timor-Leste",
    code: "TL",
    id: 225,
    nameAr: null,
  },
  {
    name: "Togo",
    code: "TG",
    id: 226,
    nameAr: "توغو",
  },
  {
    name: "Tokelau",
    code: "TK",
    id: 227,
    nameAr: null,
  },
  {
    name: "Tonga",
    code: "TO",
    id: 228,
    nameAr: "تونغا",
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    id: 229,
    nameAr: "ترينيداد وتوباغو",
  },
  {
    name: "Tunisia",
    code: "TN",
    id: 230,
    nameAr: "تونس",
  },
  {
    name: "Turkey",
    code: "TR",
    id: 231,
    nameAr: "تركيا",
  },
  {
    name: "Turkmenistan",
    code: "TM",
    id: 232,
    nameAr: "تركمانستان",
  },
  {
    name: "Turks and Caicos Islands",
    code: "TC",
    id: 233,
    nameAr: null,
  },
  {
    name: "Tuvalu",
    code: "TV",
    id: 234,
    nameAr: "توفالو",
  },
  {
    name: "Uganda",
    code: "UG",
    id: 235,
    nameAr: "أوغندا",
  },
  {
    name: "Ukraine",
    code: "UA",
    id: 236,
    nameAr: "أوكرانيا",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    id: 237,
    nameAr: "الإمارات العربية المتحدة",
  },
  {
    name: "United Kingdom",
    code: "GB",
    id: 238,
    nameAr: "المملكة المتحدة",
  },
  {
    name: "United States",
    code: "US",
    id: 239,
    nameAr: "الولايات المتحدة",
  },
  {
    name: "United States Minor Outlying Islands",
    code: "UM",
    id: 240,
    nameAr: null,
  },
  {
    name: "Uruguay",
    code: "UY",
    id: 241,
    nameAr: "أوروغواي",
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    id: 242,
    nameAr: "أوزبكستان",
  },
  {
    name: "Vanuatu",
    code: "VU",
    id: 243,
    nameAr: "فانواتو",
  },
  {
    name: "Venezuela",
    code: "VE",
    id: 244,
    nameAr: "فنزويلا",
  },
  {
    name: "Viet Nam",
    code: "VN",
    id: 245,
    nameAr: null,
  },
  {
    name: "Virgin Islands, British",
    code: "VG",
    id: 246,
    nameAr: null,
  },
  {
    name: "Virgin Islands, U.s.",
    code: "VI",
    id: 247,
    nameAr: null,
  },
  {
    name: "Wallis and Futuna",
    code: "WF",
    id: 248,
    nameAr: null,
  },
  {
    name: "Western Sahara",
    code: "EH",
    id: 249,
    nameAr: "الصحراء الغربية",
  },
  {
    name: "Yemen",
    code: "YE",
    id: 250,
    nameAr: "اليمن",
  },
  {
    name: "Zambia",
    code: "ZM",
    id: 251,
    nameAr: "زامبيا",
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    id: 252,
    nameAr: "زيمبابوي",
  },
];

export const countries = [
  {
    name: "Afghanistan",
    code: "AF",
    id: 1,
    nameAr: "أفغانستان",
  },
  {
    name: "Albania",
    code: "AL",
    id: 3,
    nameAr: "ألبانيا",
  },
  {
    name: "Algeria",
    code: "DZ",
    id: 4,
    nameAr: "الجزائر",
  },
  {
    name: "American Samoa",
    code: "AS",
    id: 5,
    nameAr: "ساموا-الأمريكي",
  },
  {
    name: "Andorra",
    code: "AD",
    id: 6,
    nameAr: "أندورا",
  },
  {
    name: "Angola",
    code: "AO",
    id: 7,
    nameAr: "أنجولا",
  },
  {
    name: "Anguilla",
    code: "AI",
    id: 8,
    nameAr: "أنجويلا",
  },
  {
    name: "Antarctica",
    code: "AQ",
    id: 9,
    nameAr: "المنطقة القطبية الجنوبية",
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    id: 10,
    nameAr: "أنتيغوا وباربودا",
  },
  {
    name: "Argentina",
    code: "AR",
    id: 11,
    nameAr: "الأرجنتين",
  },
  {
    name: "Armenia",
    code: "AM",
    id: 12,
    nameAr: "أرمينيا",
  },
  {
    name: "Australia",
    code: "AU",
    id: 14,
    nameAr: "أستراليا",
  },
  {
    name: "Austria",
    code: "AT",
    id: 15,
    nameAr: "النمسا",
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    id: 16,
    nameAr: "أذربيجان",
  },
  {
    name: "Bahamas",
    code: "BS",
    id: 17,
    nameAr: "باهاماس",
  },
  {
    name: "Bahrain",
    code: "BH",
    id: 18,
    nameAr: "البحرين",
  },
  {
    name: "Bangladesh",
    code: "BD",
    id: 19,
    nameAr: "بنجلاديش",
  },
  {
    name: "Barbados",
    code: "BB",
    id: 20,
    nameAr: "باربادوس",
  },
  {
    name: "Belarus",
    code: "BY",
    id: 21,
    nameAr: "بيلاروس",
  },
  {
    name: "Belgium",
    code: "BE",
    id: 22,
    nameAr: "بلجيكا",
  },
  {
    name: "Belize",
    code: "BZ",
    id: 23,
    nameAr: "بيليز",
  },
  {
    name: "Benin",
    code: "BJ",
    id: 24,
    nameAr: "بنين",
  },
  {
    name: "Bermuda",
    code: "BM",
    id: 25,
    nameAr: "برمودا",
  },
  {
    name: "Bhutan",
    code: "BT",
    id: 26,
    nameAr: "بوتان",
  },
  {
    name: "Bolivia",
    code: "BO",
    id: 27,
    nameAr: "بوليفيا",
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    id: 29,
    nameAr: "البوسنة والهرسك",
  },
  {
    name: "Botswana",
    code: "BW",
    id: 30,
    nameAr: "بوتسوانا",
  },
  {
    name: "Brazil",
    code: "BR",
    id: 32,
    nameAr: "البرازيل",
  },
  {
    name: "Bulgaria",
    code: "BG",
    id: 35,
    nameAr: "بلغاريا",
  },
  {
    name: "Burkina Faso",
    code: "BF",
    id: 36,
    nameAr: "بوركينا فاسو",
  },
  {
    name: "Burundi",
    code: "BI",
    id: 37,
    nameAr: "بوروندي",
  },
  {
    name: "Cambodia",
    code: "KH",
    id: 38,
    nameAr: "كمبوديا",
  },
  {
    name: "Cameroon",
    code: "CM",
    id: 39,
    nameAr: "كاميرون",
  },
  {
    name: "Canada",
    code: "CA",
    id: 40,
    nameAr: "كندا",
  },
  {
    name: "Cape Verde",
    code: "CV",
    id: 41,
    nameAr: "الرأس الأخضر",
  },
  {
    name: "Cayman Islands",
    code: "KY",
    id: 42,
    nameAr: "جزر كايمان",
  },
  {
    name: "Central African Republic",
    code: "CF",
    id: 43,
    nameAr: "جمهورية أفريقيا الوسطى",
  },
  {
    name: "Chad",
    code: "TD",
    id: 44,
    nameAr: "تشاد",
  },
  {
    name: "Chile",
    code: "CL",
    id: 45,
    nameAr: "تشيلي",
  },
  {
    name: "China",
    code: "CN",
    id: 46,
    nameAr: "الصين",
  },
  {
    name: "Colombia",
    code: "CO",
    id: 49,
    nameAr: "كولومبيا",
  },
  {
    name: "Comoros",
    code: "KM",
    id: 50,
    nameAr: "جزر القمر",
  },
  {
    name: "Cook Islands",
    code: "CK",
    id: 53,
    nameAr: "جزر كوك",
  },
  {
    name: "Costa Rica",
    code: "CR",
    id: 54,
    nameAr: "كوستاريكا",
  },
  {
    name: "Croatia",
    code: "HR",
    id: 56,
    nameAr: "كرواتيا",
  },
  {
    name: "Cuba",
    code: "CU",
    id: 57,
    nameAr: "كوبا",
  },
  {
    name: "Cyprus",
    code: "CY",
    id: 59,
    nameAr: "قبرص",
  },
  {
    name: "Denmark",
    code: "DK",
    id: 61,
    nameAr: "الدنمارك",
  },
  {
    name: "Djibouti",
    code: "DJ",
    id: 62,
    nameAr: "جيبوتي",
  },
  {
    name: "Dominica",
    code: "DM",
    id: 63,
    nameAr: "دومينيكا",
  },
  {
    name: "Dominican Republic",
    code: "DO",
    id: 64,
    nameAr: "جمهورية الدومينيكان",
  },
  {
    name: "Ecuador",
    code: "EC",
    id: 65,
    nameAr: "الإكوادور",
  },
  {
    name: "Egypt",
    code: "EG",
    id: 66,
    nameAr: "مصر",
  },
  {
    name: "El Salvador",
    code: "SV",
    id: 67,
    nameAr: "السلفادور",
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    id: 68,
    nameAr: "غينيا الاستوائية",
  },
  {
    name: "Eritrea",
    code: "ER",
    id: 69,
    nameAr: "إريتريا",
  },
  {
    name: "Estonia",
    code: "EE",
    id: 70,
    nameAr: "إستونيا",
  },
  {
    name: "Ethiopia",
    code: "ET",
    id: 71,
    nameAr: "إثيوبيا",
  },
  {
    name: "Faroe Islands",
    code: "FO",
    id: 73,
    nameAr: "جزر فارو",
  },
  {
    name: "Fiji",
    code: "FJ",
    id: 74,
    nameAr: "فيجي",
  },
  {
    name: "Finland",
    code: "FI",
    id: 75,
    nameAr: "فنلندا",
  },
  {
    name: "France",
    code: "FR",
    id: 76,
    nameAr: "فرنسا",
  },
  {
    name: "French Guiana",
    code: "GF",
    id: 77,
    nameAr: "غينيا الفرنسية",
  },
  {
    name: "French Polynesia",
    code: "PF",
    id: 78,
    nameAr: "بولينيزيا الفرنسية",
  },
  {
    name: "Gabon",
    code: "GA",
    id: 80,
    nameAr: "الغابون",
  },
  {
    name: "Gambia",
    code: "GM",
    id: 81,
    nameAr: "غامبيا",
  },
  {
    name: "Georgia",
    code: "GE",
    id: 82,
    nameAr: "جورجيا",
  },
  {
    name: "Germany",
    code: "DE",
    id: 83,
    nameAr: "ألمانيا",
  },
  {
    name: "Ghana",
    code: "GH",
    id: 84,
    nameAr: "غانا",
  },
  {
    name: "Greece",
    code: "GR",
    id: 86,
    nameAr: "اليونان",
  },
  {
    name: "Greenland",
    code: "GL",
    id: 87,
    nameAr: "جرينلاند",
  },
  {
    name: "Grenada",
    code: "GD",
    id: 88,
    nameAr: "غرينادا",
  },
  {
    name: "Guadeloupe",
    code: "GP",
    id: 89,
    nameAr: "غوادلوب",
  },
  {
    name: "Guam",
    code: "GU",
    id: 90,
    nameAr: "غوام",
  },
  {
    name: "Guatemala",
    code: "GT",
    id: 91,
    nameAr: "غواتيمال",
  },
  {
    name: "Guinea",
    code: "GN",
    id: 93,
    nameAr: "غينيا",
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    id: 94,
    nameAr: "غينيا بيساو",
  },
  {
    name: "Guyana",
    code: "GY",
    id: 95,
    nameAr: "غيانا",
  },
  {
    name: "Haiti",
    code: "HT",
    id: 96,
    nameAr: "هايتي",
  },
  {
    name: "Honduras",
    code: "HN",
    id: 99,
    nameAr: "هندوراس",
  },
  {
    name: "Hong Kong",
    code: "HK",
    id: 100,
    nameAr: "هونغ كونغ",
  },
  {
    name: "Hungary",
    code: "HU",
    id: 101,
    nameAr: "المجر",
  },
  {
    name: "Iceland",
    code: "IS",
    id: 102,
    nameAr: "أيسلندا",
  },
  {
    name: "India",
    code: "IN",
    id: 103,
    nameAr: "الهند",
  },
  {
    name: "Indonesia",
    code: "ID",
    id: 104,
    nameAr: "إندونيسيا",
  },
  {
    name: "Iraq",
    code: "IQ",
    id: 106,
    nameAr: "العراق",
  },
  {
    name: "Ireland",
    code: "IE",
    id: 107,
    nameAr: "جمهورية أيرلندا",
  },
  {
    name: "Italy",
    code: "IT",
    id: 110,
    nameAr: "إيطاليا",
  },
  {
    name: "Jamaica",
    code: "JM",
    id: 111,
    nameAr: "جامايكا",
  },
  {
    name: "Japan",
    code: "JP",
    id: 112,
    nameAr: "اليابان",
  },
  {
    name: "Jordan",
    code: "JO",
    id: 114,
    nameAr: "الأردن",
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    id: 115,
    nameAr: "كازاخستان",
  },
  {
    name: "Kenya",
    code: "KE",
    id: 116,
    nameAr: "كينيا",
  },
  {
    name: "Kiribati",
    code: "KI",
    id: 117,
    nameAr: "كيريباتي",
  },
  {
    name: "Kuwait",
    code: "KW",
    id: 121,
    nameAr: "الكويت",
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    id: 122,
    nameAr: "قيرغيزستان",
  },
  {
    name: "Latvia",
    code: "LV",
    id: 124,
    nameAr: "لاتفيا",
  },
  {
    name: "Lebanon",
    code: "LB",
    id: 125,
    nameAr: "لبنان",
  },
  {
    name: "Lesotho",
    code: "LS",
    id: 126,
    nameAr: "ليسوتو",
  },
  {
    name: "Liberia",
    code: "LR",
    id: 127,
    nameAr: "ليبيريا",
  },
  {
    name: "Liechtenstein",
    code: "LI",
    id: 129,
    nameAr: "ليختنشتاين",
  },
  {
    name: "Lithuania",
    code: "LT",
    id: 130,
    nameAr: "ليتوانيا",
  },
  {
    name: "Luxembourg",
    code: "LU",
    id: 131,
    nameAr: "لوكسمبورغ",
  },
  {
    name: "Madagascar",
    code: "MG",
    id: 134,
    nameAr: "مدغشقر",
  },
  {
    name: "Malawi",
    code: "MW",
    id: 135,
    nameAr: "مالاوي",
  },
  {
    name: "Malaysia",
    code: "MY",
    id: 136,
    nameAr: "ماليزيا",
  },
  {
    name: "Maldives",
    code: "MV",
    id: 137,
    nameAr: "المالديف",
  },
  {
    name: "Mali",
    code: "ML",
    id: 138,
    nameAr: "مالي",
  },
  {
    name: "Malta",
    code: "MT",
    id: 139,
    nameAr: "مالطا",
  },
  {
    name: "Marshall Islands",
    code: "MH",
    id: 140,
    nameAr: "جزر مارشال",
  },
  {
    name: "Martinique",
    code: "MQ",
    id: 141,
    nameAr: "مارتينيك",
  },
  {
    name: "Mauritania",
    code: "MR",
    id: 142,
    nameAr: "موريتانيا",
  },
  {
    name: "Mauritius",
    code: "MU",
    id: 143,
    nameAr: "موريشيوس",
  },
  {
    name: "Mayotte",
    code: "YT",
    id: 144,
    nameAr: "مايوت",
  },
  {
    name: "Mexico",
    code: "MX",
    id: 145,
    nameAr: "المكسيك",
  },
  {
    name: "Monaco",
    code: "MC",
    id: 148,
    nameAr: "موناكو",
  },
  {
    name: "Mongolia",
    code: "MN",
    id: 149,
    nameAr: "منغوليا",
  },
  {
    name: "Montenegro",
    code: "ME",
    id: 150,
    nameAr: "الجبل الأسو",
  },
  {
    name: "Montserrat",
    code: "MS",
    id: 151,
    nameAr: "مونتسرات",
  },
  {
    name: "Morocco",
    code: "MA",
    id: 152,
    nameAr: "المغرب",
  },
  {
    name: "Mozambique",
    code: "MZ",
    id: 153,
    nameAr: "موزمبيق",
  },
  {
    name: "Myanmar",
    code: "MM",
    id: 154,
    nameAr: " ميانمار",
  },
  {
    name: "Namibia",
    code: "NA",
    id: 155,
    nameAr: "ناميبيا",
  },
  {
    name: "Nauru",
    code: "NR",
    id: 156,
    nameAr: "ناورو",
  },
  {
    name: "Nepal",
    code: "NP",
    id: 157,
    nameAr: "نيبال",
  },
  {
    name: "Netherlands",
    code: "NL",
    id: 158,
    nameAr: "هولندا",
  },
  {
    name: "New Zealand",
    code: "NZ",
    id: 161,
    nameAr: "نيوزيلندا",
  },
  {
    name: "Nicaragua",
    code: "NI",
    id: 162,
    nameAr: "نيكاراجوا",
  },
  {
    name: "Niger",
    code: "NE",
    id: 163,
    nameAr: "النيجر",
  },
  {
    name: "Nigeria",
    code: "NG",
    id: 164,
    nameAr: "نيجيريا",
  },
  {
    name: "Northern Mariana Islands",
    code: "MP",
    id: 167,
    nameAr: "جزر ماريانا الشمالية",
  },
  {
    name: "Norway",
    code: "NO",
    id: 168,
    nameAr: "النرويج",
  },
  {
    name: "Oman",
    code: "OM",
    id: 169,
    nameAr: "عُمان",
  },
  {
    name: "Pakistan",
    code: "PK",
    id: 170,
    nameAr: "باكستان",
  },
  {
    name: "Palau",
    code: "PW",
    id: 171,
    nameAr: "بالاو",
  },
  {
    name: "Panama",
    code: "PA",
    id: 173,
    nameAr: "بنما",
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    id: 174,
    nameAr: "بابوا غينيا الجديدة",
  },
  {
    name: "Paraguay",
    code: "PY",
    id: 175,
    nameAr: "باراغواي",
  },
  {
    name: "Peru",
    code: "PE",
    id: 176,
    nameAr: "بيرو",
  },
  {
    name: "Philippines",
    code: "PH",
    id: 177,
    nameAr: "الفلبين",
  },
  {
    name: "Poland",
    code: "PL",
    id: 179,
    nameAr: "بولندا",
  },
  {
    name: "Portugal",
    code: "PT",
    id: 180,
    nameAr: "البرتغال",
  },
  {
    name: "Puerto Rico",
    code: "PR",
    id: 181,
    nameAr: "بورتوريكو",
  },
  {
    name: "Qatar",
    code: "QA",
    id: 182,
    nameAr: "قطر",
  },
  {
    name: "Romania",
    code: "RO",
    id: 184,
    nameAr: "رومانيا",
  },
  {
    name: "Rwanda",
    code: "RW",
    id: 186,
    nameAr: "رواندا",
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    id: 197,
    nameAr: "السعودية",
  },
  {
    name: "Senegal",
    code: "SN",
    id: 198,
    nameAr: "السنغال",
  },
  {
    name: "Serbia",
    code: "RS",
    id: 199,
    nameAr: "صربيا",
  },
  {
    name: "Seychelles",
    code: "SC",
    id: 201,
    nameAr: "سيشل",
  },
  {
    name: "Sierra Leone",
    code: "SL",
    id: 202,
    nameAr: "سيراليون",
  },
  {
    name: "Singapore",
    code: "SG",
    id: 203,
    nameAr: "سنغافورة",
  },
  {
    name: "Slovakia",
    code: "SK",
    id: 205,
    nameAr: "سلوفاكيا",
  },
  {
    name: "Slovenia",
    code: "SI",
    id: 206,
    nameAr: "سلوفينيا",
  },
  {
    name: "Somalia",
    code: "SO",
    id: 208,
    nameAr: "الصومال",
  },
  {
    name: "South Africa",
    code: "ZA",
    id: 209,
    nameAr: "جنوب أفريقيا",
  },
  {
    name: "South Sudan",
    code: "SS",
    id: 211,
    nameAr: "جنوب السودان",
  },
  {
    name: "Spain",
    code: "ES",
    id: 212,
    nameAr: "إسبانيا",
  },
  {
    name: "Sri Lanka",
    code: "LK",
    id: 213,
    nameAr: "سريلانكا",
  },
  {
    name: "Sudan",
    code: "SD",
    id: 214,
    nameAr: "السودان",
  },
  {
    name: "Suriname",
    code: "SR",
    id: 215,
    nameAr: "سورينام",
  },
  {
    name: "Swaziland",
    code: "SZ",
    id: 217,
    nameAr: "سوازيلند",
  },
  {
    name: "Sweden",
    code: "SE",
    id: 218,
    nameAr: "السويد",
  },
  {
    name: "Switzerland",
    code: "CH",
    id: 219,
    nameAr: "سويسرا",
  },
  {
    name: "Tajikistan",
    code: "TJ",
    id: 222,
    nameAr: "طاجيكستان",
  },
  {
    name: "Thailand",
    code: "TH",
    id: 224,
    nameAr: "تايلاند",
  },
  {
    name: "Togo",
    code: "TG",
    id: 226,
    nameAr: "توغو",
  },
  {
    name: "Tonga",
    code: "TO",
    id: 228,
    nameAr: "تونغا",
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    id: 229,
    nameAr: "ترينيداد وتوباغو",
  },
  {
    name: "Tunisia",
    code: "TN",
    id: 230,
    nameAr: "تونس",
  },
  {
    name: "Turkey",
    code: "TR",
    id: 231,
    nameAr: "تركيا",
  },
  {
    name: "Turkmenistan",
    code: "TM",
    id: 232,
    nameAr: "تركمانستان",
  },
  {
    name: "Tuvalu",
    code: "TV",
    id: 234,
    nameAr: "توفالو",
  },
  {
    name: "Uganda",
    code: "UG",
    id: 235,
    nameAr: "أوغندا",
  },
  {
    name: "Ukraine",
    code: "UA",
    id: 236,
    nameAr: "أوكرانيا",
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    id: 237,
    nameAr: "الإمارات العربية المتحدة",
  },
  {
    name: "United Kingdom",
    code: "GB",
    id: 238,
    nameAr: "المملكة المتحدة",
  },
  {
    name: "United States",
    code: "US",
    id: 239,
    nameAr: "الولايات المتحدة",
  },
  {
    name: "Uruguay",
    code: "UY",
    id: 241,
    nameAr: "أوروغواي",
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    id: 242,
    nameAr: "أوزبكستان",
  },
  {
    name: "Vanuatu",
    code: "VU",
    id: 243,
    nameAr: "فانواتو",
  },
  {
    name: "Venezuela",
    code: "VE",
    id: 244,
    nameAr: "فنزويلا",
  },
  {
    name: "Western Sahara",
    code: "EH",
    id: 249,
    nameAr: "الصحراء الغربية",
  },
  {
    name: "Yemen",
    code: "YE",
    id: 250,
    nameAr: "اليمن",
  },
  {
    name: "Zambia",
    code: "ZM",
    id: 251,
    nameAr: "زامبيا",
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    id: 252,
    nameAr: "زيمبابوي",
  },
];
