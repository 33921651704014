<template>
  <v-container fluid>
    <v-row class="fill-height">
      <v-col cols="12" class="text-end">
        <v-btn color="primary" @click="openCreateDialog" :disabled="loading">
          Create Company
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :sort-by="['createdAt']"
          :sort-desc="true"
          fixed-header
          :locale="$vuetify.rtl ? 'ar' : 'en'"
          :loading="loading"
          :headers="headers"
          :items="companies"
          :search="search"
          class="elevation-0 bg-transparent"
          calculate-widths
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="mr-auto text-no-wrap">
              <v-btn
                icon
                :to="{
                  name: 'CompanyPromoCodes',
                  params: { companyId: item._id },
                }"
              >
                <v-icon small> mdi-open-in-new </v-icon>
              </v-btn>

              <v-icon small class="mr-2" @click="openEditDialog(item)">
                mdi-pencil
              </v-icon>
              <!--              <v-icon-->
              <!--                  v-if="canDelete"-->
              <!--                  small-->
              <!--                  @click="removeItem(item)"-->
              <!--              >-->
              <!--                mdi-delete-->
              <!--              </v-icon>-->
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <companyCreateEdit ref="companyCreateEditRef" @onSave="getCompanyData" />
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import companyCreateEdit from "@/components/Admin/CorporateProgram/CompanyCreateEdit";

export default {
  name: "Companies",

  components: {
    companyCreateEdit,
  },

  data: () => {
    return {
      search: '',
      loading: false,
      companies: [],
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "_id",
          divider: true,
          width: 100,
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
          divider: true,
        },
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
          divider: true,
        },
        {
          text: "Description",
          align: "start",
          sortable: true,
          value: "description",
          divider: true,
        },
        {
          text: "Actions",
          align: "end",
          sortable: false,
          value: "actions",
          divider: true,
        },
      ],
    };
  },

  methods: {
    ...mapActions("corporateProgram", ["getCompanies"]),

    openEditDialog(item) {
      this.$refs.companyCreateEditRef.open(item);
    },

    openCreateDialog() {
      this.$refs.companyCreateEditRef.open();
    },

    async getCompanyData() {
      this.loading = true;
      this.companies = await this.getCompanies();
      this.loading = false;
    },
  },

  mounted() {
    this.getCompanyData();
  },
};
</script>

<style scoped lang="scss">
.v-data-table::v-deep .v-data-table__wrapper {
  max-height: 450px !important;
}
</style>
