<template>
  <v-container fluid>
    <CreateTherapist />


    <v-data-table
      :headers="headers"
      :items="therapists"
      item-key="name"
      :search="search"
      class="elevation-0 bg-transparent"
      calculate-widths
      :loading="loading"
      :sort-by="['createdAt']"
      :sort-desc="true"
    >
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search"
          class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.onHold="{ item }">
        <v-checkbox
            v-model="item.onHold"
            :disabled="editableItemId !== item._id"
        ></v-checkbox>
      </template>

      <template v-slot:item.isDisabled="{ item }">
        <v-checkbox
            v-model="item.isDisabled"
            :disabled="editableItemId !== item._id"
        ></v-checkbox>
      </template>

      <template v-slot:item.capacity="{ item }">
        <v-text-field
              type="number"
              :disabled="editableItemId !== item._id"
              class="d-inline-block"
              v-model="item.capacity"
          ></v-text-field>
      </template>


      <template v-slot:item.matchingLocation="{ item }">
          <v-autocomplete
              multiple
              :disabled="editableItemId !== item._id"
              v-model="item.matchingLocation"
              :items="countries"
              item-text="name"
              item-value="name"
          ></v-autocomplete>
      </template>

      <template v-slot:item.tier="{ item }">
        <v-autocomplete
            multiple
            :disabled="editableItemId !== item._id"
            v-model="item.tier"
            :items="tiers"
            item-text="name"
            item-value="_id"
        ></v-autocomplete>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
            small
            icon
            v-if="!editableItemId"
            @click="editItem(item)"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
        <template v-if="editableItemId === item._id">
          <v-btn
              small
              icon
              @click="cancel(item)"
          >
            <v-icon small>mdi-close</v-icon>
          </v-btn>
          <v-btn
              small
              icon
              @click="save(item)"
              color="secondary"
          >
            <v-icon small>mdi-check</v-icon>
          </v-btn>
        </template>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { countries } from "@/constants/countries";
import {mapActions, mapGetters} from "vuex";
import CreateTherapist from "@/components/Admin/CreateTherapist.vue";

export default {
  name: "TherapistsPanel",
  components: { CreateTherapist },

  data () {
    return {
      search: '',
      countries,
      loading: false,
      therapists: [],
      initialItem: null,
      editableItem:  null
    }
  },
  computed: {
    ...mapGetters("therapistTier", [
      "tiers"
    ]),

    headers () {
      return [
        {
          text: 'First Name',
          align: 'start',
          sortable: false,
          value: 'firstName',
        },
        { text: 'Last Name', value: 'lastName' },
        { text: 'Email', value: 'email' },
        { text: 'On Hold', value: 'onHold' },
        { text: 'Disabled', value: 'isDisabled' },
        { text: 'Monthly Capacity', value: 'capacity' },
        { text: 'Active Clients', value: 'activeClients' },
        { text: 'Tier', value: 'tier' },
        { text: 'Location', value: 'matchingLocation' },
        { text: 'Actions', value: 'actions' },
      ]
    },
    editableItemId() {
      return this.editableItem?._id;
    }
  },
  methods: {
    ...mapActions('user', [
        'getTherapistsForAdminTherapistPanel',
        'updateTherapistsForAdminTherapistPanel'
    ]),
    ...mapActions("therapistTier", ["getTiers"]),

    editItem(item) {
      this.editableItem = JSON.parse(JSON.stringify(item));
    },

    async save(item) {
      let saveData = {};
      let changed = false;
      for(let i of ['isDisabled', 'onHold', 'capacity', 'tier', 'matchingLocation']) {
        if(JSON.stringify(item[i]) !== JSON.stringify(this.editableItem[i])) {
          if(i === 'capacity') {
            saveData[i] = +item[i];
          } else {
            saveData[i] = item[i];
          }
          changed = true;
        }
      }

      if(changed) {
        this.loading = true
        let res = await this.updateTherapistsForAdminTherapistPanel({
          id: item._id,
          data: saveData
        }).catch(e => {
          console.error('updateTherapistsForAdminTherapistPanel', e);
          this.cancel(item)
        })
        this.loading = false;
        console.log("res: ", res);

      }
      this.editableItem = null;
    },

    cancel(item) {
      const currentTherapist = this.therapists.find(e => e._id === item._id);
      Object.assign(currentTherapist, this.editableItem);
      this.editableItem = null;
    },
  },

  async mounted() {
    this.loading = true;
    this.therapists = await this.getTherapistsForAdminTherapistPanel();
    await this.getTiers();
    this.loading = false;
  }
}
</script>

<style scoped></style>
