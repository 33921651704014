var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('CreateTherapist'),_c('v-data-table',{staticClass:"elevation-0 bg-transparent",attrs:{"headers":_vm.headers,"items":_vm.therapists,"item-key":"name","search":_vm.search,"calculate-widths":"","loading":_vm.loading,"sort-by":['createdAt'],"sort-desc":true},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.onHold",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.editableItemId !== item._id},model:{value:(item.onHold),callback:function ($$v) {_vm.$set(item, "onHold", $$v)},expression:"item.onHold"}})]}},{key:"item.isDisabled",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{attrs:{"disabled":_vm.editableItemId !== item._id},model:{value:(item.isDisabled),callback:function ($$v) {_vm.$set(item, "isDisabled", $$v)},expression:"item.isDisabled"}})]}},{key:"item.capacity",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{staticClass:"d-inline-block",attrs:{"type":"number","disabled":_vm.editableItemId !== item._id},model:{value:(item.capacity),callback:function ($$v) {_vm.$set(item, "capacity", $$v)},expression:"item.capacity"}})]}},{key:"item.matchingLocation",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{attrs:{"multiple":"","disabled":_vm.editableItemId !== item._id,"items":_vm.countries,"item-text":"name","item-value":"name"},model:{value:(item.matchingLocation),callback:function ($$v) {_vm.$set(item, "matchingLocation", $$v)},expression:"item.matchingLocation"}})]}},{key:"item.tier",fn:function(ref){
var item = ref.item;
return [_c('v-autocomplete',{attrs:{"multiple":"","disabled":_vm.editableItemId !== item._id,"items":_vm.tiers,"item-text":"name","item-value":"_id"},model:{value:(item.tier),callback:function ($$v) {_vm.$set(item, "tier", $$v)},expression:"item.tier"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.editableItemId)?_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.editableItemId === item._id)?[_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.cancel(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-close")])],1),_c('v-btn',{attrs:{"small":"","icon":"","color":"secondary"},on:{"click":function($event){return _vm.save(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-check")])],1)]:_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }