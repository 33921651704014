<template>
  <v-container>
    <v-row class="fill-height">
      <v-col cols="12" md="6">
        <v-autocomplete
          :items="therapists"
          v-model="selectedTherapist"
          item-text="email"
          return-object
          clearable
          label="Select Therapist"
          :loading="therapistsLoading"
          dense
          outlined
          hide-details
        ></v-autocomplete>
        <v-btn
          :disabled="!selectedTherapist"
          color="primary"
          @click="chooseTherapist"
          class="mt-3"
        >
          Choose Therapist
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" v-if="!isChiefTherapist">
        <v-autocomplete
          :items="clientsData"
          v-model="selectedClient"
          item-text="email"
          return-object
          clearable
          :loading="clientsLoading"
          label="Select Client"
          dense
          outlined
          hide-details
        ></v-autocomplete>
        <v-btn
          :disabled="!selectedClient"
          color="primary"
          @click="chooseClient"
          class="mt-3"
        >
          Choose Client
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "AccessUsers",
  mixins: [UserMixin],

  data: () => {
    return {
      clientsData: [],
      clientsLoading: false,
      selectedClient: null,
      therapists: [],
      therapistsLoading: false,
      selectedTherapist: null,
    };
  },

  methods: {
    ...mapActions("user", [
      "getTherapistsForAdmin",
      "getClientsForAdmin",
      "loginForAdmin",
    ]),

    async chooseTherapist() {
      await this.loginForAdmin({
        id: this.selectedTherapist.id,
        email: this.selectedTherapist.email,
      });

      location.replace("/calendar");
    },

    async chooseClient() {
      await this.loginForAdmin({
        id: this.selectedClient.id,
        email: this.selectedClient.email,
      });

      location.replace("/calendar");
    },
  },

  mounted() {
    this.getTherapistsForAdmin().then((res) => {
      this.therapists = res;
      this.therapistsLoading = false;
    });

    this.getClientsForAdmin().then((res) => {
      this.clientsData = res;
      this.clientsLoading = false;
    });
  },
};
</script>

<style scoped></style>
