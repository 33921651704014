<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="4">
        <v-autocomplete
          v-model="filterByTherapistEmail"
          class="my-2"
          :items="therapistList"
          dense
          hide-details
          outlined
          color="blue-grey lighten-2"
          label="by Therapist Email"
          clearable
          item-value="_id"
          item-text="email"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete
          v-model="filterByClientEmail"
          class="my-2"
          :items="clientList"
          dense
          hide-details
          outlined
          color="blue-grey lighten-2"
          label="by Client Email"
          clearable
          item-value="_id"
          item-text="email"
        />
      </v-col>
      <v-col cols="12" md="4">
        <v-autocomplete
          v-model="filterByStatus"
          class="my-2"
          :items="statusList"
          dense
          hide-details
          outlined
          color="blue-grey lighten-2"
          label="by Status"
          clearable
        />
      </v-col>
    </v-row>
    <v-row class="fill-height">
      <v-col cols="12">
        <v-data-table
          height="500"
          :locale="$vuetify.rtl ? 'ar' : 'en'"
          :loading="loading"
          sort-by="eventStartDate"
          :sort-desc="true"
          :headers="headers"
          fixed-header
          :items="filteredEvents"
          class="elevation-0 bg-transparent"
          calculate-widths
        >
          <!-- <template v-slot:body.prepend="data">
            <tr>
              <template v-for="header in data.headers">
                <td :key="header.value">
                  <template v-if="header.value === 'therapistEmail'">
                    <v-autocomplete
                      v-model="filterByTherapistEmail"
                      class="my-2"
                      :items="therapistList"
                      dense
                      hide-details
                      outlined
                      color="blue-grey lighten-2"
                      label="by Therapist Email"
                      clearable
                      item-value="_id"
                      item-text="email"
                    />
                  </template>

                  <template v-if="header.value === 'clientEmail'">
                    <v-autocomplete
                      v-model="filterByClientEmail"
                      class="my-2"
                      :items="clientList"
                      dense
                      hide-details
                      outlined
                      color="blue-grey lighten-2"
                      label="by Client Email"
                      clearable
                      item-value="_id"
                      item-text="email"
                    />
                  </template>

                  <template v-if="header.value === 'status'">
                    <v-autocomplete
                      v-model="filterByStatus"
                      class="my-2"
                      :items="statusList"
                      dense
                      hide-details
                      outlined
                      color="blue-grey lighten-2"
                      label="by Status"
                      clearable
                    />
                  </template>
                </td>
              </template>
            </tr>
          </template> -->

          <template v-slot:item.therapistEmail="{ item }">
            {{ item.therapist ? item.therapist.email : "" }}
          </template>

          <template v-slot:item.therapistName="{ item }">
            {{ getUserName(item.therapist) }}
          </template>

          <template v-slot:item.clientEmail="{ item }">
            {{ item.client ? item.client.email : "" }}
          </template>

          <template v-slot:item.clientName="{ item }">
            {{ getUserName(item.client) }}
          </template>

          <template v-slot:item.eventStartDate="{ item }">
            {{ item.eventStartDate | moment("DD-MMM-YYYY LT") }}
          </template>

          <template v-slot:item.status="{ item }">
            <div class="d-flex align-center">
              <span class="me-2">
                {{ item.status }}
              </span>
              <v-btn
                v-if="
                  [
                    'booked',
                    'bookedFree',
                    'done',
                    'declined',
                    'unsuccessful',
                  ].includes(item.status)
                "
                class="ms-auto"
                icon
                @click="openStatusChangeDialog(item)"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
          </template>

          <template v-slot:item.sessionDuration="{ item }">
            {{ item.free ? "20min" : "50min" }}
          </template>

          <template v-slot:item.logs="{ item }">
            <v-tooltip
              bottom
              v-for="(log, i) in item.logs"
              :key="i"
              color="primary"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="ma-1"
                  small
                  color="primary"
                  outlined
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ log.date | moment("DD-MMM-YYYY LT") }}
                </v-btn>
              </template>
              <div>{{ log.event }}</div>
            </v-tooltip>

            <!--              <v-expansion-panels-->
            <!--                  :accordion="false"-->
            <!--                  :ripple="false"-->
            <!--              >-->
            <!--                <v-expansion-panel-->
            <!--                    v-for="(log, i) in item.logs"-->
            <!--                    :key="i"-->
            <!--                >-->
            <!--                  <v-expansion-panel-header>-->
            <!--                    {{ log.date | moment("DD-MMM-YYYY LT") }}-->
            <!--                  </v-expansion-panel-header>-->
            <!--                  <v-expansion-panel-content>-->
            <!--                    {{ log.event }}-->
            <!--                  </v-expansion-panel-content>-->
            <!--                </v-expansion-panel>-->
            <!--              </v-expansion-panels>-->
          </template>
          <!--          <template v-slot:item.createdAt="{ item }">-->
          <!--            {{ item.createdAt | moment("DD/MM/YYYY hh:mm A") }}-->
          <!--          </template>-->
        </v-data-table>
      </v-col>
    </v-row>
    <EventStatusChange ref="eventStatusChangeRef" />
  </v-container>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";
import EventStatusChange from "@/components/Admin/EventStatusChange";
import { mapActions } from "vuex";
// import UserAvatar from "@/components/UserAvatar";

export default {
  name: "SessionSchedule",
  components: {
    // UserAvatar
    EventStatusChange,
  },

  mixins: [UserMixin],

  data: () => {
    return {
      loading: false,

      events: [],

      filterByTherapistEmail: null,
      filterByClientEmail: null,
      filterByStatus: null,

      headers: [
        {
          text: "Therapist Email",
          align: "start",
          sortable: false,
          value: "therapistEmail",
        },
        {
          text: "Therapist Name",
          align: "start",
          sortable: false,
          value: "therapistName",
        },
        {
          text: "Client Email",
          align: "start",
          sortable: false,
          value: "clientEmail",
        },
        {
          text: "Client Name",
          align: "start",
          sortable: false,
          value: "clientName",
          width: "120px",
        },
        {
          text: "Session Start Date",
          align: "start",
          sortable: true,
          value: "eventStartDate",
          width: "180px",
        },
        {
          text: "Session Status",
          align: "start",
          sortable: false,
          value: "status",
        },
        {
          text: "Session Duration",
          align: "start",
          sortable: false,
          value: "sessionDuration",
        },
        {
          text: "Logs",
          align: "start",
          sortable: false,
          value: "logs",
          divider: true,
        },
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "_id",
          divider: true,
        },
      ],
    };
  },

  computed: {
    filteredEvents() {
      let filteredEvents = JSON.parse(JSON.stringify(this.events));

      if (this.filterByTherapistEmail) {
        filteredEvents = filteredEvents.filter(
          (e) => e.therapist?._id === this.filterByTherapistEmail
        );
      }

      if (this.filterByClientEmail) {
        filteredEvents = filteredEvents.filter(
          (e) => e.client?._id === this.filterByClientEmail
        );
      }

      if (this.filterByStatus) {
        filteredEvents = filteredEvents.filter(
          (e) => e.status === this.filterByStatus
        );
      }

      return filteredEvents;
    },

    therapistList() {
      return Object.values(
        this.filteredEvents.reduce((r, a) => {
          if (!r[a.therapist._id]) {
            r[a.therapist._id] = {};
          }
          r[a.therapist.id] = a.therapist;
          return r;
        }, {})
      );
    },

    clientList() {
      return Object.values(
        this.filteredEvents.reduce((r, a) => {
          if (a.client) {
            if (!r[a.client._id]) {
              r[a.client._id] = {};
            }
            r[a.client.id] = a.client;
          }
          return r;
        }, {})
      );
    },

    statusList() {
      return Object.values(
        this.filteredEvents.reduce((r, a) => {
          if (!r[a.status]) {
            r[a.status] = {};
          }
          r[a.status] = a.status;
          return r;
        }, {})
      );
    },
  },

  methods: {
    ...mapActions("calendar", ["getEventsForAdmin"]),

    openStatusChangeDialog(item) {
      this.$refs.eventStatusChangeRef.open(item);
    },
  },

  mounted() {
    this.awaitPromiseCallback({
      key: "getMe",
      cb: async () => {
        this.loading = true;
        this.events = await this.getEventsForAdmin();
        // console.log("this.events: ", this.events);
        this.loading = false;
      },
    });
  },
};
</script>

<style scoped></style>
