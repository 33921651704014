var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{staticClass:"my-2",attrs:{"items":_vm.therapistList,"dense":"","hide-details":"","outlined":"","color":"blue-grey lighten-2","label":"by Therapist Email","clearable":"","item-value":"_id","item-text":"email"},model:{value:(_vm.filterByTherapistEmail),callback:function ($$v) {_vm.filterByTherapistEmail=$$v},expression:"filterByTherapistEmail"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{staticClass:"my-2",attrs:{"items":_vm.clientList,"dense":"","hide-details":"","outlined":"","color":"blue-grey lighten-2","label":"by Client Email","clearable":"","item-value":"_id","item-text":"email"},model:{value:(_vm.filterByClientEmail),callback:function ($$v) {_vm.filterByClientEmail=$$v},expression:"filterByClientEmail"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-autocomplete',{staticClass:"my-2",attrs:{"items":_vm.statusList,"dense":"","hide-details":"","outlined":"","color":"blue-grey lighten-2","label":"by Status","clearable":""},model:{value:(_vm.filterByStatus),callback:function ($$v) {_vm.filterByStatus=$$v},expression:"filterByStatus"}})],1)],1),_c('v-row',{staticClass:"fill-height"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0 bg-transparent",attrs:{"height":"500","locale":_vm.$vuetify.rtl ? 'ar' : 'en',"loading":_vm.loading,"sort-by":"eventStartDate","sort-desc":true,"headers":_vm.headers,"fixed-header":"","items":_vm.filteredEvents,"calculate-widths":""},scopedSlots:_vm._u([{key:"item.therapistEmail",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.therapist ? item.therapist.email : "")+" ")]}},{key:"item.therapistName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserName(item.therapist))+" ")]}},{key:"item.clientEmail",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.client ? item.client.email : "")+" ")]}},{key:"item.clientName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserName(item.client))+" ")]}},{key:"item.eventStartDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.eventStartDate,"DD-MMM-YYYY LT"))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"me-2"},[_vm._v(" "+_vm._s(item.status)+" ")]),(
                [
                  'booked',
                  'bookedFree',
                  'done',
                  'declined',
                  'unsuccessful' ].includes(item.status)
              )?_c('v-btn',{staticClass:"ms-auto",attrs:{"icon":""},on:{"click":function($event){return _vm.openStatusChangeDialog(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1)]}},{key:"item.sessionDuration",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(item.free ? "20min" : "50min")+" ")]}},{key:"item.logs",fn:function(ref){
              var item = ref.item;
return _vm._l((item.logs),function(log,i){return _c('v-tooltip',{key:i,attrs:{"bottom":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"small":"","color":"primary","outlined":"","dark":""}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm._f("moment")(log.date,"DD-MMM-YYYY LT"))+" ")])]}}],null,true)},[_c('div',[_vm._v(_vm._s(log.event))])])})}}])})],1)],1),_c('EventStatusChange',{ref:"eventStatusChangeRef"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }