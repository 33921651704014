var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"align-center mb-3"},[_c('v-col',[_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"label":"Filter by email","hide-details":""},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"label":"Filter by First Name","hide-details":""},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"label":"Filter by Last Name","hide-details":""},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1),_c('v-col',[_c('v-text-field',{staticClass:"mt-0 pt-0",attrs:{"label":"Filter by Username","hide-details":""},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1),_c('v-col',[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","small":""}},'v-btn',attrs,false),on),[_vm._v(" Filters ")])]}}])},[_c('draggable',{staticStyle:{"max-height":"300px","background":"#fff","overflow-y":"auto"},attrs:{"group":"groups","tag":"div"},on:{"start":function($event){_vm.dragging = true},"end":function($event){_vm.dragging = false}},model:{value:(_vm.headers),callback:function ($$v) {_vm.headers=$$v},expression:"headers"}},_vm._l((_vm.headers),function(group,i){return _c('div',{key:i},[_c('div',{staticClass:"d-flex align-center"},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"dense":"","hide-details":"","label":group.group},model:{value:(group.visible),callback:function ($$v) {_vm.$set(group, "visible", $$v)},expression:"group.visible"}})],1),_c('draggable',{staticStyle:{"list-style":"none","padding-left":"32px"},attrs:{"list":group.children,"group":{ name: 'items', pull: 'clone', put: false },"disabled":_vm.dragging,"tag":"ul"}},_vm._l((group.children),function(item){return _c('li',{key:item.text},[_c('small',[_vm._v(_vm._s(item.text))])])}),0)],1)}),0)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.flattenedHeaders,"items":_vm.filteredClients.data,"page":_vm.currentPage,"items-per-page":_vm.perPage,"server-items-length":_vm.totalClients,"loading":_vm.loading,"hide-default-header":"","fixed-header":"","footer-props":{
      'items-per-page-options': [10, 20, 25, 50, 100],
    }},on:{"update:page":function($event){_vm.currentPage=$event},"update:itemsPerPage":function($event){_vm.perPage=$event},"update:items-per-page":function($event){_vm.perPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('thead',{staticClass:"v-data-table-header custom-header"},[_c('draggable',_vm._b({attrs:{"tag":"tr","list":_vm.headers}},'draggable',_vm.dragOptions,false),_vm._l((_vm.headers),function(item){return _c('th',{directives:[{name:"show",rawName:"v-show",value:(item.visible),expression:"item.visible"}],key:item.id,staticClass:"text-center border-right border-bottom",attrs:{"colspan":item.children.length}},[_vm._v(" "+_vm._s(item.group)+" ")])}),0),_c('tr',_vm._l((_vm.flattenedHeaders),function(header,index){return _c('th',{key:index,staticClass:"text-start",staticStyle:{"width":"200px","min-width":"200px"}},[_vm._v(" "+_vm._s(header.text)+" ")])}),0)],1)]},proxy:true},{key:"item.isDisabled",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-checkbox',{staticClass:"me-2",attrs:{"readonly":"","plain":"","ripple":false},model:{value:(item.isDisabled),callback:function ($$v) {_vm.$set(item, "isDisabled", $$v)},expression:"item.isDisabled"}}),(!_vm.isChiefTherapist)?_c('v-btn',{staticClass:"ms-auto",attrs:{"icon":""},on:{"click":function($event){return _vm.openIsDisabledDialog(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1)]}},{key:"item.profile.createdAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.profile.createdAt,"DD-MM-YYYY LT"))+" ")]}},{key:"item.currentPromotionCode.corporatePlan.code",fn:function(ref){
    var item = ref.item;
return [(item.currentPromotionCode && item.currentPromotionCode.code)?[_vm._v(" "+_vm._s(item.currentPromotionCode.code)+" "),_c('v-btn',{staticClass:"ms-auto",attrs:{"icon":""},on:{"click":function($event){return _vm.removeBenefitsCode(item)}}},[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]:_vm._e()]}},{key:"item.company.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.company && item.company.type !== "Insurance" ? item.company.name : "-")+" ")]}},{key:"item.insuranceName",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.company && item.company.type === "Insurance" ? item.company.name : "-")+" ")]}},{key:"item.currentPromotionCode.corporatePlan.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.currentPromotionCode && item.company.type !== "Insurance" ? item.currentPromotionCode.corporatePlan.name : "-")+" ")]}},{key:"item.insurancePlan",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.currentPromotionCode && item.company.type === "Insurance" ? item.currentPromotionCode.corporatePlan.name : "-")+" ")]}},{key:"item.insurance.status",fn:function(ref){
    var item = ref.item;
return [(item.currentPromotionCode && item.company.type === 'Insurance')?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e()]}},{key:"item.subscriptionStartDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserSubscriptionStartDate(item))+" ")]}},{key:"item.subscriptionEndDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserSubscriptionEndDate(item))+" ")]}},{key:"item.subscriptionRenewalDate",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserSubscriptionEndDate(item))+" ")]}},{key:"item.therapistEmail",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.therapist && item.therapist.email ? item.therapist.email : "-")+" ")]}},{key:"item.therapistName",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.therapist && item.therapist.profile ? item.therapist.profile.firstName + " " + item.therapist.profile.lastName : "-")+" ")]}},{key:"item.metadata.numberLiveSessionsHave",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"me-2"},[_vm._v(" "+_vm._s(item.metadata.numberLiveSessionsHave)+" ")]),(!_vm.isChiefTherapist)?_c('v-btn',{staticClass:"ms-auto",attrs:{"icon":""},on:{"click":function($event){return _vm.openLiveSessionAddDialog(item, false)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1)]}},{key:"item.metadata.numberCorporateLiveSessionsHave",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"me-2"},[_vm._v(" "+_vm._s(item.metadata.numberCorporateLiveSessionsHave)+" ")]),(!_vm.isChiefTherapist && item.company)?_c('v-btn',{staticClass:"ms-auto",attrs:{"icon":""},on:{"click":function($event){return _vm.openLiveSessionAddDialog(item, false, true)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1)]}},{key:"item.metadata.numberFreeLiveSessionsHave",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"me-2"},[_vm._v(" "+_vm._s(item.metadata.numberFreeLiveSessionsHave)+" ")]),(!_vm.isChiefTherapist)?_c('v-btn',{staticClass:"ms-auto",attrs:{"icon":""},on:{"click":function($event){return _vm.openLiveSessionAddDialog(item, true)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e()],1)]}}])}),_c('ClientIsDisabledChange',{ref:"ClientIsDisabledChangeRef"}),_c('ClientDatabaseCreateEdit',{ref:"clientDatabaseCreateEditRef",attrs:{"insuranceCompanies":_vm.insuranceCompanies},on:{"onSave":_vm.getCompanyAndInsuranceUsersData}}),_c('ClientLiveSessionAdd',{ref:"ClientLiveSessionAddRef"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }