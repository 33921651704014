<template>
  <v-dialog v-model="dialog" max-width="500px" @close="close" scrollable>
    <v-card class="rounded-lg pa-5" v-if="clientRef">
      <v-card-text>
      <v-btn @click="close" elevation="2" icon class="close-icon pa-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
        <v-form ref="clientCodeForm">
          <template
              v-if="client.insurance"
          >
            <v-text-field
                outlined
                dense
                hide-details
                :label="$t('Insurance Policy Number')"
                class="mt-5"
                :rules="[(value) => !!value || 'Required.']"
                v-model="client.insurance.insurancePolicyNumber">
            </v-text-field>

            <v-text-field
                outlined
                dense
                hide-details
                :label="$t('First Name')"
                class="mt-5"
                :rules="[(value) => !!value || 'Required.']"
                v-model="client.insurance.firstName">
            </v-text-field>

            <v-text-field
                outlined
                dense
                hide-details
                :label="$t('Last Name')"
                class="mt-5"
                :rules="[(value) => !!value || 'Required.']"
                v-model="client.insurance.lastName">
            </v-text-field>

            <v-menu
                ref="birthDateMenuRef"
                v-model="birthDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <!--                  <v-text-field :readonly="!editable" v-model="insuranceData.birthDate" :rules="dobRules"-->
                <v-text-field
                    outlined
                    dense
                    class="mt-5"
                    hide-details
                    v-model="client.insurance.birthDate"
                    :label="$t('DateOfBirth')"
                    v-bind="attrs"
                    v-on="on"
                    :rules="[(value) => !!value || 'Required.']"
                ></v-text-field>
              </template>
              <v-date-picker
                  :locale="$vuetify.rtl ? 'ar' : 'en'"

                  no-title
                  v-model="client.insurance.birthDate"
                  :show-current="false"
                  :active-picker.sync="dateActivePicker"
                  :max="maxBirthDate"
                  min="1950-01-01"
                  @change="saveBirthDate"
              ></v-date-picker>
            </v-menu>

<!--            <v-text-field-->
<!--                outlined-->
<!--                dense-->
<!--                hide-details-->
<!--                :rules="[(value) => !!value || 'Required.']"-->
<!--                :label="$t('Gender')" class="mt-5"-->
<!--                v-model="client.insurance.gender">-->
<!--            </v-text-field>-->

            <v-text-field
                outlined
                dense
                hide-details
                :rules="[(value) => !!value || 'Required.']"
                :label="$t('Email')"
                class="mt-5"
                v-model="client.email">
            </v-text-field>

            <v-select
                outlined
                dense
                hide-details
                class="mt-5"
                :label="$t('Status')"
                v-model="client.insurance.status"
                :items="statusItems"
                item-text="name"
                item-value="value">
            </v-select>


            <template v-if="client.insurance.status !== 'declined'">
              <v-select
                  v-if="!clientRef.company"
                  outlined
                  dense
                  hide-details
                  :label="$t('Company')"
                  :rules="[(value) => !!value || 'Required.']"
                  class="mt-5"
                  v-model="client.company"
                  :items="insuranceCompanies"
                  item-text="name"
                  item-value="value"
                  return-object
              >
              </v-select>

              <v-select
                  v-if="!clientRef.company && client.company"
                  outlined
                  dense
                  hide-details
                  :rules="[(value) => !!value || 'Required.']"
                  :disabled="!!clientRef.company"
                  :label="$t('PromoCode')"
                  class="mt-5"
                  v-model="client.currentPromotionCode"
                  :items="activePromotionCodes"
                  item-text="name"
                  item-value="value"
                  return-object
              >
              </v-select>
            </template>
          </template>



          <v-text-field
              outlined
              dense
              hide-details
              disabled
              :label="$t('Company Name')"
              class="mt-5"
              v-if="clientRef.company"
              v-model="client.company.name">
          </v-text-field>

          <v-text-field
              outlined
              dense
              hide-details
              disabled
              :label="$t('PromoCode')"
              class="mt-5"
              v-if="clientRef.company"
              :value="currentPromoCodeName">
          </v-text-field>

          <v-textarea class="mt-5" v-model="client.notes" :rules='[
          (v) =>
          (v && v.length <= 500) ||
          this.$t("Max 500 characters"),
          ]'
          rows="4"
          label="Notes"
          outlined
          dense
          >
          </v-textarea>


        </v-form>
<!--  -->
<!--        <v-select-->
<!--            outlined-->
<!--            dense-->
<!--            hide-details-->
<!--            :label="$t('Plan')"-->
<!--            class="mt-5"-->
<!--            v-model="client.plan"-->
<!--            :items="plans"-->
<!--            item-text="name"-->
<!--            item-value="value">-->
<!--        </v-select>-->

      </v-card-text>

      <v-card-actions class="pb-6">
        <v-btn
          color="primary"
          class="mx-auto px-5"
          large
          :disabled="loading"
          :loading="loading"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapMutations} from "vuex";

export default {
  name: "CompanyCreateEdit",

  props: ['insuranceCompanies'],

  data: () => {
    return {
      dialog: false,
      loading: false,
      companyRef: null,
      dateActivePicker: null,
      birthDateMenu: false,
      clientRef: null,
      client: null,
      statusItems: [
        {
          name: 'Approved',
          value: 'approved'
        },
        {
          name: 'Submitted',
          value: 'submitted'
        },
        {
          name: 'Declined',
          value: 'declined'
        },
        {
          name: 'Cancelled',
          value: 'cancelled'
        },
        {
          name: 'Out of pocket',
          value: 'outOfPocket'
        }
      ],
      companyTypes: [
        {
          name: 'Corporate',
          value: 'Corporate'
        },
        {
          name: 'Insurance',
          value: 'Insurance'
        }
      ],
      plans: [
        {
          name: 'test plan',
          value: 'testPlan'
        }
      ]
    };
  },



  computed: {
    maxBirthDate() {
      let today = new Date();
      today.setFullYear(today.getFullYear() - 18);
      return today.toISOString().substring(0, 10);
    },

    activePromotionCodes() {
      return this.client?.company?.PromotionCodes?.filter(e => e.active)
    },

    currentPromoCodeName() {
      return this.client?.currentPromotionCode?.name || this.client?.currentPromotionCode?.id || '-'
    }
    // buttonDisabled() {
    //   return !this.company.name;
    // },
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.refreshData();
      }
    },
    birthDateMenu(val) {
      val && setTimeout(() => (this.dateActivePicker = "YEAR"));
    },
  },

  methods: {
    ...mapActions("user", ["updateCompanyAndInsuranceUser"]),

    ...mapMutations(["addNotification"]),

    async save() {
      this.loading = true;
      if (this.$refs.clientCodeForm.validate()) {

        let reqData = {};

        if(this.client.email !== this.clientRef.email) {
          reqData.email = this.client.email;
        }

        if(this.client.insurance) {
          reqData.insurance = {};
          reqData.insurance.id = this.client.insurance._id;
          reqData.insurance.birthDate = this.client.insurance.birthDate;
          reqData.insurance.firstName = this.client.insurance.firstName;
          reqData.insurance.insurancePolicyNumber = this.client.insurance.insurancePolicyNumber;
          reqData.insurance.lastName = this.client.insurance.lastName;
          reqData.insurance.status = this.client.insurance.status;
        }

        if(this.client.notes) {
          reqData.notes = this.client.notes;
        }

        if(!this.clientRef.company && this.client.company) {
          reqData.companyId = this.client.company._id;
          reqData.promotionCodeId = this.client.currentPromotionCode._id;
        }

        try {

          await this.updateCompanyAndInsuranceUser({id: this.client._id, data: reqData});

          this.$emit("onSave");
          this.close();
        } catch (e) {
          this.addNotification({
            message: e?.response?.data?.message || "Error",
            icon: "mdi-alert",
            timeout: 10000,
          });
        }
      }

      this.loading = false;
    },

    async open(client) {
      this.clientRef = client;
      this.client = { ...client };
      this.dialog = true;
    },

    close() {
      this.refreshData();
      this.dialog = false;
    },

    refreshData() {
      this.clientRef = null
      this.company = null;
    },

    saveBirthDate(date) {
      this.$refs.birthDateMenuRef.save(date);
    },
  },
};
</script>

<style scoped></style>
