<template>
  <v-dialog v-model="dialog" max-width="500px" @close="close">
    <v-card class="rounded-lg">
      <v-btn @click="close" elevation="2" icon class="close-icon pa-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-text class="pa-8" v-if="report">
        <v-btn
          color="secondary"
          class="mb-4"
          :loading="loading"
          @click="resetDefault"
        >
          Reset To Default
        </v-btn>

        <v-autocomplete
          :items="statuses"
          v-model="selectedEventStatus"
          clearable
          label="Status"
          :loading="loading"
          @change="onChangeStatus"
        ></v-autocomplete>

        <v-autocomplete
          :items="reportsForStatus"
          v-model="selectedEventReportActionType"
          clearable
          label="Action Type"
          :loading="loading"
        ></v-autocomplete>
      </v-card-text>
      <v-card-text class="pa-8" v-else> 
        <p class="mb-0 mt-3 text-center body-2">There is no available action</p>
      </v-card-text>
      <v-card-actions class="pb-6">
        <v-btn
          v-if="report"
          color="primary"
          class="mx-auto px-5"
          large
          :disabled="buttonDisabled || loading"
          :loading="loading"
          @click="save()"
        >
          Save
        </v-btn>
        <v-btn v-else color="primary" class="mx-auto px-5" large :disabled="loading" :loading="loading" @click="dialog = !dialog">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

const ReportActionTypesEnum = {
  Completed: "Session completed",
  CompletedFree: "Free Session completed",

  BookedFromClient: "Session booked from client",
  BookedFromClientFree: "Free Session booked from client",

  CanceledFromTherapist: "Session canceled from therapist",
  CanceledFromClient: "Session canceled from client",

  CanceledFromTherapist12: "Session canceled before 12 hours from therapist",
  CanceledFromClient12: "Session canceled before 12 hours from client",
  CanceledAgreedToNotCharge: "Session Canceled Agreed To Not Charge",

  CanceledTechnical: "Session canceled technical issue",
  CanceledOther: "Session canceled other issue",

  BookedNotShownFromTherapist: "Session booked but no show from therapist",
  BookedNotShownFromClient: "Session booked but no show from client",
};

export default {
  name: "EventStatusChange",
  data: () => {
    return {
      dialog: false,
      loading: false,
      selectedEvent: null,
      report: null,
      selectedEventStatus: "",
      selectedEventReportActionType: "",
    };
  },

  computed: {
    statuses() {
      let eventStatus = this.selectedEvent?.status;

      return [
        {
          text: "Done",
          value: "done",
          disabled: ["available", "removed", "declined"].includes(eventStatus),
          divider: false,
          header: "",
        },
        {
          text: "Unsuccessful",
          value: "unsuccessful",
          disabled: ["available", "removed"].includes(eventStatus),
          divider: false,
          header: "",
        },
        {
          text: "",
          value: "",
          disabled: true,
          divider: true,
          header: "",
        },
        {
          text: "Available",
          value: "available",
          disabled: true,
          divider: false,
          header: "",
        },
        {
          text: "Removed",
          value: "removed",
          disabled: true,
          divider: false,
          header: "",
        },
        {
          text: "Declined",
          value: "declined",
          disabled: true,
          divider: false,
          header: "",
        },
        {
          text: "Booked",
          value: "booked",
          disabled: true,
          divider: false,
          header: "",
        },
        {
          text: "Booked Free",
          value: "bookedFree",
          disabled: true,
          divider: false,
          header: "",
        },
      ];
    },

    reportsForStatus() {
      let actionType = this.report?.actionType;

      let mapper = {
        booked: [
          {
            text: ReportActionTypesEnum.BookedFromClient,
            value: ReportActionTypesEnum.BookedFromClient,
            disabled: actionType === ReportActionTypesEnum.BookedFromClient,
            divider: false,
            header: "",
          },
        ],
        bookedFree: [
          {
            text: ReportActionTypesEnum.BookedFromClientFree,
            value: ReportActionTypesEnum.BookedFromClientFree,
            disabled: actionType === ReportActionTypesEnum.BookedFromClientFree,
            divider: false,
            header: "",
          },
        ],
        done: [
          {
            text: ReportActionTypesEnum.Completed,
            value: ReportActionTypesEnum.Completed,
            disabled: actionType === ReportActionTypesEnum.Completed,
            divider: false,
            header: "",
          },
          {
            text: ReportActionTypesEnum.CompletedFree,
            value: ReportActionTypesEnum.CompletedFree,
            disabled: actionType === ReportActionTypesEnum.CompletedFree,
            divider: false,
            header: "",
          },
        ],
        unsuccessful: [
          {
            text: ReportActionTypesEnum.CanceledTechnical,
            value: ReportActionTypesEnum.CanceledTechnical,
            disabled: actionType === ReportActionTypesEnum.CanceledTechnical,
            divider: false,
            header: "",
          },
          {
            text: ReportActionTypesEnum.CanceledOther,
            value: ReportActionTypesEnum.CanceledOther,
            disabled: actionType === ReportActionTypesEnum.CanceledOther,
            divider: false,
            header: "",
          },
          {
            text: ReportActionTypesEnum.BookedNotShownFromClient,
            value: ReportActionTypesEnum.BookedNotShownFromClient,
            disabled:
              actionType === ReportActionTypesEnum.BookedNotShownFromClient,
            divider: false,
            header: "",
          },
          {
            text: ReportActionTypesEnum.BookedNotShownFromTherapist,
            value: ReportActionTypesEnum.BookedNotShownFromTherapist,
            disabled:
              actionType === ReportActionTypesEnum.BookedNotShownFromTherapist,
            divider: false,
            header: "",
          },
          {
            text: ReportActionTypesEnum.CanceledAgreedToNotCharge,
            value: ReportActionTypesEnum.CanceledAgreedToNotCharge,
            disabled:
                actionType === ReportActionTypesEnum.CanceledAgreedToNotCharge,
            divider: false,
            header: "",
          },
        ],
        declined: [
          {
            text: ReportActionTypesEnum.CanceledFromTherapist,
            value: ReportActionTypesEnum.CanceledFromTherapist,
            disabled: actionType === ReportActionTypesEnum.CanceledFromTherapist,
            divider: false,
            header: "",
          },
          {
            text: ReportActionTypesEnum.CanceledFromTherapist12,
            value: ReportActionTypesEnum.CanceledFromTherapist12,
            disabled: actionType === ReportActionTypesEnum.CanceledFromTherapist12,
            divider: false,
            header: "",
          },
          {
            text: ReportActionTypesEnum.CanceledFromClient,
            value: ReportActionTypesEnum.CanceledFromClient,
            disabled: actionType === ReportActionTypesEnum.CanceledFromClient,
            divider: false,
            header: "",
          },
          {
            text: ReportActionTypesEnum.CanceledFromClient12,
            value: ReportActionTypesEnum.CanceledFromClient12,
            disabled: actionType === ReportActionTypesEnum.CanceledFromClient12,
            divider: false,
            header: "",
          }
        ],
      };
      return mapper[this.selectedEventStatus];
    },

    buttonDisabled() {
      return (
        !this.selectedEventReportActionType ||
        (this.selectedEventStatus === this.selectedEvent?.status &&
          this.selectedEventReportActionType === this?.report?.actionType)
      );
    },
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.refreshData();
      }
    },
  },

  methods: {
    ...mapActions("calendar", [
      "changeEventStatus",
      "getEventAndReportForAdmin",
    ]),
    ...mapMutations(["addNotification"]),

    async save() {
      this.loading = true;

      try {
        let data = await this.changeEventStatus({
          id: this.selectedEvent._id,
          data: {
            status: this.selectedEventStatus,
            reportId: this.report._id,
            reportActionType: this.getReportActionTypeKey(
              this.selectedEventReportActionType
            ),
            reportOldActionType: this.getReportActionTypeKey(
              this.report.actionType
            ),
          },
        });
        this.selectedEvent.status = data.status;
        this.selectedEvent.logs = data.logs;
        this.close();
      } catch (e) {
        this.addNotification({
          message: e.response.data.message,
          icon: "mdi-alert",
          timeout: 10000,
        });
      }

      this.loading = false;
    },

    getReportActionTypeKey(value) {
      return Object.entries(ReportActionTypesEnum).find(
        (e) => e[1] === value
      )[0];
    },

    async open(selectedEvent) {
      console.log("=>(EventStatusChange.vue:331) selectedEvent", selectedEvent);
      this.selectedEvent = selectedEvent;
      this.selectedEventStatus = selectedEvent.status;
      this.dialog = true;
      try {
        this.loading = true;
        let data = await this.getEventAndReportForAdmin({
          eventId: selectedEvent._id,
        });
        this.report = data.report;
        console.log("=>(EventStatusChange.vue:341) data report", data);
        this.selectedEventReportActionType = data.report.actionType;
        this.loading = false;
      } catch (e) {
        this.loading = false;

        // this.addNotification({
        //   message: e?.response?.data?.message,
        //   icon: "mdi-alert",
        //   timeout: 10000,
        // });
      }
    },

    onChangeStatus(e) {
      this.selectedEventReportActionType = "";
      if (e === "done") {
        this.selectedEventReportActionType = this.selectedEvent.free
          ? ReportActionTypesEnum.CompletedFree
          : ReportActionTypesEnum.Completed;
      }
    },

    resetDefault() {
      this.selectedEventStatus = this.selectedEvent.status;
      this.selectedEventReportActionType = this.report.actionType;
    },

    close() {
      this.refreshData();
      this.dialog = false;
    },

    refreshData() {
      this.selectedEvent = null;
      this.report = null;
      this.selectedEventStatus = "";
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
