<template>
  <v-dialog v-model="dialog" max-width="500px" @close="close">
    <v-card class="rounded-lg">
      <v-btn @click="close" elevation="2" icon class="close-icon pa-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-text class="pa-8" v-if="selectedUser">
        <h5 class="mb-3">{{ selectedUser.email }}</h5>

        <v-checkbox
          v-model="isDisabled"
          :loading="loading"
          label="Disabled"
        ></v-checkbox>
      </v-card-text>
      <v-card-actions class="pb-6">
        <v-btn
          color="primary"
          class="mx-auto px-5"
          large
          :disabled="buttonDisabled || loading"
          :loading="loading"
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "ClientLiveSessionAdd",
  data: () => {
    return {
      dialog: false,
      loading: false,
      selectedUser: null,
      isDisabled: false,
    };
  },

  computed: {
    buttonDisabled() {
      return (
        !Number.isInteger(this.liveSessionCount) && +this.liveSessionCount === 0
      );
    },
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.refreshData();
      }
    },
  },

  methods: {
    ...mapActions("user", ["updateClientStatusForAdmin"]),

    ...mapMutations(["addNotification"]),

    async save() {
      this.loading = true;

      try {
        await this.updateClientStatusForAdmin({
          clientId: this.selectedUser._id,
          data: {
            isDisabled: this.isDisabled,
          },
        });

        this.selectedUser.isDisabled = this.isDisabled;
        this.close();
      } catch (e) {
        this.addNotification({
          message: e.response.data.message,
          icon: "mdi-alert",
          timeout: 10000,
        });
      }

      this.loading = false;
    },

    async open(selectedUser) {
      this.selectedUser = selectedUser;
      this.isDisabled = selectedUser.isDisabled || false
      this.dialog = true;
    },

    close() {
      this.refreshData();
      this.dialog = false;
    },

    refreshData() {
      this.selectedUser = null;
      this.liveSessionCount = 0;
    },
  },
};
</script>

<style scoped></style>
