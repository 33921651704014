<template>
  <v-container>
    <v-row class="fill-height">
      <v-col cols="12">
        <v-btn color="primary" :loading="loading" @click="makeClientsCsv">
          All Clients Data
        </v-btn>

        <v-btn
          color="primary"
          :loading="loading"
          class="mx-3"
          @click="makeClientLiveSessionDataReportCsv"
        >
          All Clients Session Data
        </v-btn>

        <v-btn
          color="primary"
          :loading="loading"
          class="mx-3"
          @click="makeClientNoTherapistReportCsv"
        >
          All Clients No Therapist Data
        </v-btn>
      </v-col>

      <v-col cols="12">

        <v-btn
          color="primary"
          :loading="loading"
          @click="getReportTherapistSessionCountMonthlyCsv"
        >
          Therapist Live Session Data - Monthly
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          :loading="loading"
          @click="makeTherapistAllTimeReport"
        >
          Therapist Live Session Data
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          :loading="loading"
          @click="getClientStatusReportCsv"
        >
          Client Status Report
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          :loading="loading"
          @click="getClientInitialAssessmentReportCsv"
        >
          Client Initial Assessment Report
        </v-btn>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          :loading="loading"
          @click="getCompanyClientActivityReportCsv"
          class="mr-5"
        >
          Company Client Activity Report
        </v-btn>

        <v-btn
            color="primary"
            :loading="loading"
            @click="getCompanySessionsActivityReportCsv"
        >
          Company Sessions Activity Report
        </v-btn>
      </v-col>

      <!--        <v-col class="d-flex align-center">-->
      <!--          <v-text-field-->
      <!--              v-model="reportDate"-->
      <!--              ref="reportDateRef"-->
      <!--              class="mr-3"-->
      <!--              hide-details-->
      <!--              placeholder="MM/YYYY"-->
      <!--              label="Date MM/YYYY"-->
      <!--              validate-on-blur-->
      <!--              :rules="[v => !!v || 'This field is required']"-->
      <!--          />-->
      <!--          <v-btn-->
      <!--              color="primary"-->
      <!--              :loading="loading"-->
      <!--              @click="makeMonthReportCsv"-->
      <!--          >-->
      <!--            Get Report-->
      <!--          </v-btn>-->
      <!--        </v-col>-->
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable */

import { mapActions } from "vuex";
import FileSaver from "file-saver";

export default {
  name: "DataExport",

  data: () => {
    return {
      loading: false,
      reportDate: "",
    };
  },

  methods: {
    // ...mapActions('user', ['getClientsExport']),
    ...mapActions("report", [
      "getTherapistAllTimeReport",
      "getClientReport",
      "getClientLiveSessionDataReport",
      "getClientNoTherapistReport",
      "getReportTherapistSessionCountMonthly",
      "getClientStatusReport",
      "getClientInitialAssessmentReport",
      "getCompanyClientActivityReport",
      "getCompanySessionsActivityReport"
    ]),

    async makeClientsCsv() {
      this.loading = true;
      const data = await this.getClientReport();
      this.loading = false;
      const blob = new Blob([new Uint8Array(data.data)]);
      // const blob = new Blob(data, {type: "text/xlsx;charset=utf-8"});
      FileSaver.saveAs(blob, "talktime-client-report.xlsx");
    },

    async makeClientLiveSessionDataReportCsv() {
      this.loading = true;
      const data = await this.getClientLiveSessionDataReport();
      this.loading = false;
      const blob = new Blob([new Uint8Array(data.data)]);
      FileSaver.saveAs(blob, "talktime-client-session-report.xlsx");
    },

    async makeClientNoTherapistReportCsv() {
      this.loading = true;
      const data = await this.getClientNoTherapistReport();
      this.loading = false;
      const blob = new Blob([new Uint8Array(data.data)]);
      FileSaver.saveAs(blob, "talktime-client-no-therapist-report.xlsx");
    },

    async makeTherapistAllTimeReport() {
      this.loading = true;
      const data = await this.getTherapistAllTimeReport();
      this.loading = false;
      const blob = new Blob([new Uint8Array(data.data)]);
      FileSaver.saveAs(blob, "talktime-therapist-live-session-report.xlsx");
    },

    async getReportTherapistSessionCountMonthlyCsv() {
      this.loading = true;
      const data = await this.getReportTherapistSessionCountMonthly();
      this.loading = false;
      const blob = new Blob([new Uint8Array(data.data)]);
      FileSaver.saveAs(
        blob,
        "talktime-therapist-live-session-data-monthly-report.xlsx"
      );
    },

    async getClientStatusReportCsv() {
      this.loading = true;
      const data = await this.getClientStatusReport();
      this.loading = false;
      const blob = new Blob([new Uint8Array(data.data)]);
      FileSaver.saveAs(
        blob,
        "client-status-report.xlsx"
      );
    },

    async getClientInitialAssessmentReportCsv() {
      this.loading = true;
      const data = await this.getClientInitialAssessmentReport();
      this.loading = false;
      const blob = new Blob([new Uint8Array(data.data)]);
      FileSaver.saveAs(
        blob,
        "client-initial-assessment-report.xlsx"
      );
    },

    async getCompanyClientActivityReportCsv() {
      this.loading = true;
      const data = await this.getCompanyClientActivityReport();
      this.loading = false;
      const blob = new Blob([new Uint8Array(data.data)]);
      FileSaver.saveAs(
        blob,
        "company-client-activity-report.xlsx"
      );
    },

    async getCompanySessionsActivityReportCsv() {
      this.loading = true;
      const data = await this.getCompanySessionsActivityReport();
      this.loading = false;
      const blob = new Blob([new Uint8Array(data.data)]);
      FileSaver.saveAs(
        blob,
        "company-sessions-activity-report.xlsx"
      );
    },

    // async makeMonthReportCsv() {
    //   if(!this.$refs.reportDateRef.validate()) {
    //     this.$refs.reportDateRef.tryAutofocus()
    //     this.$refs.reportDateRef.focus()
    //     return
    //   }
    //
    //   this.loading = true;
    //   let data = await this.getMonthlyReport({date: this.reportDate})
    //   this.loading = false;
    //   console.log("data: ", data);
    //   const blob = new Blob([new Uint8Array(data.data)]);
    //   FileSaver.saveAs(blob, `talktime-report-${this.reportDate}.xlsx`);
    //
    //
    // }
  },
};
</script>

<style scoped></style>
