<template>
  <v-container fluid>
    <v-row class="fill-height">
      <v-col cols="12" class="text-end">
        <v-btn color="primary" @click="openCreateDialog" :disabled="loading">
          Create Corporate Plan
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          fixed-header
          :locale="$vuetify.rtl ? 'ar' : 'en'"
          :loading="loading"
          :sort-by="['createdAt']"
          :sort-desc="true"
          :search="search"
          :headers="headers"
          :items="corporatePlans"
          class="elevation-0 bg-transparent"
          calculate-widths
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              label="Search"
              class="mx-4"
            ></v-text-field>
          </template>
          <template v-slot:item.location="{ item }">
            {{item.location.join(', ')}}
          </template>

          <template v-slot:item.tier="{ item }">
            {{item.tier.map(e => e.name).join(', ')}}
          </template>

          <template v-slot:item.actions="{ item }">
            <div class="mr-auto text-no-wrap">
              <v-icon small class="mr-2" @click="openEditDialog(item)">
                mdi-pencil
              </v-icon>
              <!--              <v-icon-->
              <!--                  v-if="canDelete"-->
              <!--                  small-->
              <!--                  @click="removeItem(item)"-->
              <!--              >-->
              <!--                mdi-delete-->
              <!--              </v-icon>-->
            </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <CorporatePlanCreateEdit
      ref="corporatePlanCreateEditRef"
      @onSave="getCorporatePlansData"
    />
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CorporatePlanCreateEdit from "@/components/Admin/CorporateProgram/CorporatePlanCreateEdit";

export default {
  name: "CorporatePlan",

  components: {
    CorporatePlanCreateEdit,
  },

  data: () => {
    return {
      search: '',
      loading: false,
      corporatePlans: [],
      headers: [
        {
          text: "Id",
          align: "start",
          sortable: false,
          value: "_id",
          divider: true,
          width: 100,
        },
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
          divider: true,
        },
        {
          text: "Type",
          align: "start",
          sortable: true,
          value: "type",
          divider: true,
        },
        {
          text: "Description",
          align: "start",
          sortable: true,
          value: "description",
          divider: true,
        },
        {
          text: "Recurring Period",
          align: "start",
          sortable: true,
          value: "recurringPeriod",
          divider: true,
        },
        {
          text: "Location",
          align: "start",
          sortable: true,
          value: "location",
          divider: true,
        },
        {
          text: "Tier",
          align: "start",
          sortable: true,
          value: "tier",
          divider: true,
        },
        {
          text: "Active",
          align: "start",
          sortable: true,
          value: "active",
          divider: true,
        },
        {
          text: "Live Session Add",
          align: "start",
          sortable: true,
          value: "liveSessionAdd",
          divider: true,
        },
        {
          text: "Free Live Session Add",
          align: "start",
          sortable: true,
          value: "freeLiveSessionAdd",
          divider: true,
        },
        {
          text: "Chat",
          align: "start",
          sortable: true,
          value: "chat",
          divider: true,
        },
        {
          text: "Actions",
          align: "end",
          sortable: false,
          value: "actions",
          divider: true,
        },
      ],
    };
  },


  computed: {
    ...mapGetters("therapistTier", [
      "tiers"
    ])
  },

  methods: {
    ...mapActions("corporatePlan", ["getCorporatePlans"]),
    ...mapActions("therapistTier", ["getTiers"]),

    openEditDialog(item) {
      this.$refs.corporatePlanCreateEditRef.open(item);
    },

    openCreateDialog() {
      this.$refs.corporatePlanCreateEditRef.open();
    },

    async getCorporatePlansData() {
      this.loading = true;
      this.corporatePlans = await this.getCorporatePlans();
      this.loading = false;
    },
  },

  mounted() {
    this.getCorporatePlansData();
    this.getTiers();
  },
};
</script>

<style scoped lang="scss">
.v-data-table::v-deep .v-data-table__wrapper {
  max-height: 450px !important;
}
</style>