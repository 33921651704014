var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-0 bg-transparent",attrs:{"sort-by":['createdAt'],"sort-desc":true,"fixed-header":"","locale":_vm.$vuetify.rtl ? 'ar' : 'en',"loading":_vm.loading,"headers":_vm.headers,"items":_vm.filteredUsers,"calculate-widths":"","custom-sort":_vm.customSort},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.company && !item.insurance ? "Corporate" : "Insurance")+" ")]}},{key:"item.insurancePolicyNumber",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.insurance ? item.insurance.insurancePolicyNumber : "-")+" ")]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.company ? item.lastPromoCode || "-" : "-")+" ")]}},{key:"item.plan",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getUserPlanName(item))+" ")]}},{key:"item.firstName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.insurance ? item.insurance.firstName : "-")+" ")]}},{key:"item.lastName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.insurance ? item.insurance.lastName : "-")+" ")]}},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.company ? "-" : "-")+" ")]}},{key:"item.therapistEmail",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.therapist && item.therapist.email ? item.therapist.email : '-')+" ")]}},{key:"item.therapistName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.therapist && item.therapist.profile ? item.therapist.profile.firstName + ' ' + item.therapist.profile.lastName : '-')+" ")]}},{key:"item.dob",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.insurance ? item.insurance.birthDate : "-")+" ")]}},{key:"item.gender",fn:function(){return [_vm._v(" "+_vm._s("-")+" ")]},proxy:true},{key:"item.customCreatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDateTime(_vm.getCustomCreatedAt(item)))+" ")]}},{key:"item.companyName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.insurance ? item.insurance.name || "-" : item.company.name || "-")+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.insurance ? item.insurance.status || "-" : item.company.status || "-")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"mr-auto text-no-wrap"},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.openEditDialog(item)}}},[_vm._v(" mdi-pencil ")])],1)]}}])}),_c('ClientDatabaseCreateEdit',{ref:"clientDatabaseCreateEditRef",attrs:{"insuranceCompanies":_vm.insuranceCompanies},on:{"onSave":_vm.getCompanyAndInsuranceUsersData}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }