<template>
  <v-dialog v-model="dialog" max-width="500px" @close="close">
    <v-card class="rounded-lg">
      <v-btn @click="close" elevation="2" icon class="close-icon pa-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-form    ref="corporatePlanForm"
                 v-model="valid"
                 lazy-validation
                 @submit.prevent>
      <v-card-text class="pa-8">
        <v-text-field
          v-model="company.name"
          label="Name"
          :loading="loading"
        ></v-text-field>
        <v-select
          :disabled="!!companyRef"
          v-model="company.type"
          label="Type"
          :rules="[rules.required]"
          :items="companyTypes"
          :loading="loading"
          item-text="name"
          item-value="value"
        ></v-select>

        <v-textarea
          v-model="company.description"
          label="Description"
          :rows="3"
          :loading="loading"
        ></v-textarea>
      </v-card-text>
      <v-card-actions class="pb-6">
        <v-btn
          color="primary"
          class="mx-auto px-5"
          large
          :disabled="buttonDisabled || loading"
          :loading="loading"
          @click="save"
        >
          Save
        </v-btn>
      </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations } from "vuex";

export default {
  name: "CompanyCreateEdit",
  data: () => {
    return {
      dialog: false,
      loading: false,
      companyRef: null,
      company: {
        name: "",
        description: "",
      },
      companyTypes: [
        {
          name: 'Corporate',
          value: 'Corporate'
        },
        {
          name: 'Insurance',
          value: 'Insurance'
        }
      ],
      rules: {
        required: (value) => !!value || "Required.",
      },
      valid: false,
    };
  },

  computed: {
    buttonDisabled() {
      return !this.company.name;
    },
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.refreshData();
      }
    },
  },

  methods: {
    ...mapActions("corporateProgram", ["createCompany", "updateCompany"]),

    ...mapMutations(["addNotification"]),

    async save() {
      if (this.$refs.corporatePlanForm.validate()) {
        this.loading = true;

        try {
          if (this.companyRef) {
            await this.updateCompany({
              id: this.companyRef._id,
              updateData: this.company,
            });
          } else {
            await this.createCompany({
              createData: this.company,
            });
          }

          this.$emit("onSave");
          this.close();
        } catch (e) {
          this.addNotification({
            message: e?.response?.data?.message || "Error",
            icon: "mdi-alert",
            timeout: 10000,
          });
        }

        this.loading = false;
      }
    },

    async open(company) {
      this.companyRef = company;
      this.company = { ...company };
      this.dialog = true;
    },

    close() {
      this.refreshData();
      this.dialog = false;
    },

    refreshData() {
      (this.companyRef = null),
        (this.company = {
          name: "",
          type: "",
          description: "",
        });
    },
  },
};
</script>

<style scoped></style>
