<template>
  <v-dialog v-model="dialog" max-width="500px" @close="close">
    <v-card class="rounded-lg">
      <v-btn @click="close" elevation="2" icon class="close-icon pa-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-text class="pa-8" v-if="company">
        <v-form
            ref="promoCodeForm"
            v-model="valid"
            lazy-validation
            @submit.prevent
        >
          <v-text-field
              v-if="company.type === 'Corporate'"
              v-model="promoCode.code"
              :disabled="!!promoCodeRef"
              label="Code"
              :loading="loading"
              :rules="[rules.required]"
              append-outer-icon="mdi-dice-3"
              @click:append-outer="genNanoId"
          ></v-text-field>

          <v-text-field
              v-model="promoCode.name"
              label="Name"
              :loading="loading"
              :rules="[rules.required]"
          ></v-text-field>

          <v-text-field
              v-model="promoCode.description"
              label="Description"
              :loading="loading"
          ></v-text-field>

          <v-text-field
              v-if="company.type === 'Corporate'"
              v-model="promoCode.domain"
              :disabled="!!promoCodeRef"
              label="Domain"
              :rules="[rules.isDomain]"
              :loading="loading"
          ></v-text-field>

<!--          <v-select-->
<!--              :items="weekdays"-->
<!--              item-text="name"-->
<!--              item-value="dayNumber"-->
<!--              label="Recurring Period Weekday"-->
<!--              :disabled="!!promoCodeRef"-->
<!--              v-model="promoCode.recurringPeriodWeekday"-->
<!--          />-->

          <v-select
              :items="activeCorporatePlans"
              item-text="name"
              item-value="_id"
              label="Corporate Plan"
              :disabled="!!promoCodeRef"
              v-model="promoCode.corporatePlan"
              :rules="[rules.required]"
          />

          <v-menu
              v-model="startDateMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                  v-model="promoCode.startDate"
                  label="Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  clearable
                  v-bind="attrs"
                  v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
                v-model="promoCode.startDate"
                @input="startDateMenu = false"
                no-title
                :min="nowDate"
            ></v-date-picker>
          </v-menu>

          <v-text-field
              v-model="promoCode.limit"
              label="limit"
              type="number"
              :rules="[rules.required, rules.minMax(0, 50000)]"
              :max="50000"
              :min="0"
              :loading="loading"
          ></v-text-field>

          <v-checkbox
              v-model="promoCode.active"
              label="Active"
              :loading="loading"
          ></v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-6">
        <v-btn
            color="primary"
            class="mx-auto px-5"
            large
            :disabled="!valid || loading"
            :loading="loading"
            @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapMutations} from "vuex";
import {nanoid} from "nanoid";

export default {
  name: "PromoCodeCreateEdit",

  props: ["corporatePlans"],

  data: () => {
    return {
      dialog: false,
      loading: false,
      valid: false,
      startDateMenu: false,
      nowDate: new Date().toISOString().slice(0, 10),

      company: null,
      promoCodeRef: null,
      promoCode: {
        name: "",
        description: "",
        code: "",
        domain: "",
        // recurringPeriodWeekday: "",
        corporatePlan: "",
        startDate: "",
        limit: 1,
        active: true,
      },

      rules: {
        required: (value) => !!value || "Required.",
        minMax: (min, max) => (v) =>
            (v >= min && v <= max) || `Min: ${min}, Max: ${max}`,
        isDomain: (v) =>
            !v ||
            /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,}$/.test(v) ||
            `Incorrect Domain`,
      },

      // weekdays: [
      //   {
      //     dayNumber: 0,
      //     name: "Sunday",
      //   },
      //   {
      //     dayNumber: 1,
      //     name: "Monday",
      //   },
      //   {
      //     dayNumber: 2,
      //     name: "Tuesday",
      //   },
      //   {
      //     dayNumber: 3,
      //     name: "Wednesday",
      //   },
      //   {
      //     dayNumber: 4,
      //     name: "Thursday",
      //   },
      //   {
      //     dayNumber: 5,
      //     name: "Friday",
      //   },
      //   {
      //     dayNumber: 6,
      //     name: "Saturday",
      //   },
      // ],
    };
  },

  computed: {
    buttonDisabled() {
      return !(
          this.promoCode.code &&
          this.promoCode.limit >= 0 &&
          this.promoCode.liveSessionCount > 0
      );
    },

    activeCorporatePlans() {
      return this.corporatePlans.filter((e) => e.active);
    },
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.refreshData();
      }
    },
  },

  methods: {
    ...mapActions("corporateProgram", ["createPromoCode", "updatePromoCode"]),

    ...mapMutations(["addNotification"]),

    async save() {
      if (this.$refs.promoCodeForm.validate()) {
        this.loading = true;

        try {
          if (this.promoCodeRef) {
            await this.updatePromoCode({
              id: this.promoCodeRef._id,
              updateData: {
                name: this.promoCode.name,
                description: this.promoCode.description,
                limit: +this.promoCode.limit,
                active: this.promoCode.active,
              },
            });
          } else {

            const createData = {
              company: this.company._id,
              name: this.promoCode.name,
              description: this.promoCode.description,
              domain: this.promoCode.domain.trim().toLowerCase(),
              corporatePlan: this.promoCode.corporatePlan,
              // recurringPeriodWeekday: this.promoCode.recurringPeriodWeekday,
              startDate: this.promoCode.startDate
                  ? new Date(this.promoCode.startDate).toISOString()
                  : "",
              limit: +this.promoCode.limit,
              active: this.promoCode.active,
            };
            if (this.promoCode.code) {
              createData.code = this.promoCode.code
            }

            await this.createPromoCode({createData});
          }

          this.$emit("onSave");
          this.close();
        } catch (e) {
          this.addNotification({
            message: e?.response?.data?.message || "Error",
            icon: "mdi-alert",
            timeout: 10000,
          });
        }

        this.loading = false;
      }
    },

    genNanoId() {
      this.promoCode.code = nanoid(8);
    },

    async open(company, promoCode) {
      this.company = company;
      if (promoCode) {
        this.promoCodeRef = promoCode;
        this.promoCode = {...promoCode};
      }
      this.dialog = true;
    },

    close() {
      this.refreshData();
      this.dialog = false;
    },

    refreshData() {
      this.company = null;
      this.promoCodeRef = null;
      this.promoCode = {
        name: "",
        description: "",
        code: "",
        domain: "",
        // recurringPeriodWeekday: "",
        startDate: "",
        corporatePlan: "",
        limit: 1,
        active: true,
      };
    },
  },
};
</script>

<style scoped></style>
