import moment from "moment";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("disorder", ["disorders"]),
  },

  methods: {
    getUserDisorders(user) {
      const itemDisorders = user?.userDisorder?.default?.map((e) => e.disorder);
      if (itemDisorders?.length) {
        return this.disorders.filter((element) =>
          itemDisorders.includes(element._id)
        );
      }
      return [];
    },

    getUserDisordersAndSelectedVariants(user) {
      const itemDisorders = user?.userDisorder?.default?.map((e) => e.disorder);
      if (itemDisorders?.length) {
        let disorders = this.disorders.filter((element) =>
          itemDisorders.includes(element._id)
        );

        return disorders.map((d) => {
          let selectedVariants = user.userDisorder.default.find(
            (e) => e.disorder === d._id
          ).selectedVariants;
          return {
            name: d.name,
            selectedVariants,
          };
        });
      }
      return [];
    },

    getUserDisordersOther(user) {
      return user?.disorder?.other;
    },

    getUserChatRoute(user) {
      this.me.rooms.some((e) => {
        if (e.users[0]._id === user._id) {
          this.$router.push({ name: "Room", params: { id: e._id } });
        }
      });
    },

    getUserProfileRoute(user) {
      this.myClients.some((e) => {
        if (e._id === user._id) {
          this.$router.push({ name: "PatientProfile", params: { id: e._id } });
        }
      });
    },

    getUserClientFileRoute(user) {
      this.myClients.some((e) => {
        if (e._id === user._id) {
          this.$router.push({ name: "ClientFile", params: { userId: e._id } });
        }
      });
    },

    getUserJournalRoute(user) {
      this.myClients.some((e) => {
        if (e._id === user._id) {
          this.$router.push({
            name: "InteractiveJournal",
            params: { userId: e._id },
          });
        }
      });
    },

    getUserNextSession(user) {
      let clientEvents = this._bookedEvents.filter(
        (e) => e.client === user._id
      );

      if (!clientEvents.length) return this.$t("No Booking");

      clientEvents.sort((a, b) => {
        new Date(a.eventStartDate) - new Date(b.eventStartDate);
      });

      return moment(clientEvents[0].eventStartDate).format("L LT");
    },

    getUserCancelAtPeriodEnd(user) {
      return (
        user.subscription?.stripeSubscription?.cancel_at_period_end ?? true
      );
    },

    getUserLiveSessionCredits(user) {
      return (
        (user?.metadata?.numberLiveSessionsHave || 0) +
          (user?.metadata?.numberCorporateLiveSessionsHave || 0) || "-"
      );
    },

    getUserFreeLiveSessionCredits(user) {
      return user?.metadata?.numberFreeLiveSessionsHave || "-";
    },

    getUserSubscriptionStartDate(user) {
      if (!user.subscription?.stripeSubscription?.start_date) return "-";
      return (
        moment(
          +user.subscription?.stripeSubscription?.start_date * 1000
        ).format("LL") || ""
      );
    },

    getUserSubscriptionEndDate(user) {
      if (!user.subscription?.stripeSubscription?.current_period_end)
        return "-";
      return (
        moment(
          +user.subscription?.stripeSubscription?.current_period_end * 1000
        ).format("LL") || ""
      );
    },

    getUserSubscriptionPlan(user) {
      return user.subscription?.stripeSubscription?.plan?.metadata?.type || "-";
    },

    getUserSubscriptionDuration(user) {
      return user.subscription?.stripeSubscription?.plan?.metadata?.name || "-";
    },
  },
};
