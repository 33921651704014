<template>
  <v-dialog v-model="dialog" max-width="500px" @close="close" scrollable>
    <v-card class="rounded-lg">
      <v-btn @click="close" elevation="2" icon class="close-icon pa-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-card-text class="pa-8">
        <v-form
          ref="corporatePlanForm"
          v-model="valid"
          lazy-validation
          @submit.prevent
        >
          <v-text-field
            v-model="corporatePlan.name"
            label="Name"
            :rules="[rules.required]"
            :loading="loading"
            :disabled="!!corporatePlanRef"
          ></v-text-field>

          <v-textarea
            v-model="corporatePlan.description"
            label="Description"
            :rows="3"
            :loading="loading"
            :disabled="!!corporatePlanRef"
          ></v-textarea>

          <v-select
            :items="planTypes"
            label="Type"
            :rules="[rules.required]"
            v-model="corporatePlan.type"
            :disabled="!!corporatePlanRef"
          ></v-select>

          <v-text-field
            v-if="corporatePlan.type === 'Recurring'"
            v-model="corporatePlan.recurringPeriod"
            label="Recurring Period (Week)"
            type="number"
            :rules="[rules.required, rules.minMax(1, 52)]"
            :loading="loading"
            :disabled="!!corporatePlanRef"
          ></v-text-field>

          <v-text-field
            v-model="corporatePlan.liveSessionAdd"
            label="Live Session Add"
            type="number"
            :rules="[rules.required, rules.minMax(0, 10)]"
            :loading="loading"
            :disabled="!!corporatePlanRef"
          ></v-text-field>

          <v-text-field
            v-model="corporatePlan.freeLiveSessionAdd"
            label="Free Live Session Add"
            type="number"
            :rules="[rules.minMax(0, 10)]"
            :loading="loading"
            :disabled="!!corporatePlanRef"
          ></v-text-field>


          <v-autocomplete
              clearable
              multiple
              label="Tier"
              v-model="corporatePlan.tier"
              :items="tiers"
              item-text="name"
              item-value="_id"
          ></v-autocomplete>

          <v-autocomplete
              clearable
              multiple
              label="Location"
              v-model="corporatePlan.location"
              :items="countries"
              item-text="name"
              item-value="name"
          ></v-autocomplete>

          <v-checkbox
            v-model="corporatePlan.active"
            label="Active"
            :loading="loading"
          ></v-checkbox>

          <v-card-actions class="pb-6">
            <v-btn
              color="primary"
              class="mx-auto px-5"
              large
              :disabled="loading"
              :loading="loading"
              @click="save"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
import { countries } from "@/constants/countries";

export default {
  name: "CorporatePlanCreateEdit",
  data: () => {
    return {
      countries,
      dialog: false,
      loading: false,
      corporatePlanRef: null,
      planTypes: ["Recurring", "oneTime"],
      corporatePlan: {
        name: "",
        type: "",
        description: "",
        recurringPeriod: "",
        location: "",
        tier: "",
        liveSessionAdd: 0,
        freeLiveSessionAdd: 0,
        active: false,
      },

      valid: false,

      rules: {
        required: (value) => !!value || "Required.",
        minMax: (min, max) => (v) =>
          (v >= min && v <= max) || `Min: ${min}, Max: ${max}`,
      },
    };
  },

  watch: {
    dialog(val) {
      if (!val) {
        this.refreshData();
      }
    },
  },

  computed: {
    ...mapGetters("therapistTier", [
      "tiers"
    ])
  },

  methods: {
    ...mapActions("corporatePlan", [
      "createCorporatePlan",
      "updateCorporatePlan",
    ]),

    ...mapMutations(["addNotification"]),

    async save() {
      if (this.$refs.corporatePlanForm.validate()) {
        this.loading = true;

        try {
          if (this.corporatePlanRef) {
            await this.updateCorporatePlan({
              id: this.corporatePlanRef._id,
              updateData: {
                tier: this.corporatePlan.tier,
                location: this.corporatePlan.location,
                active: this.corporatePlan.active,
              },
            });
          } else {
            await this.createCorporatePlan({
              createData: {
                name: this.corporatePlan.name,
                description: this.corporatePlan.description,
                type: this.corporatePlan.type,
                recurringPeriod:
                  this.corporatePlan.type === "Recurring"
                    ? parseInt(this.corporatePlan.recurringPeriod)
                    : 0,
                liveSessionAdd: parseInt(this.corporatePlan.liveSessionAdd),
                freeLiveSessionAdd: parseInt(
                  this.corporatePlan.freeLiveSessionAdd
                ),
                location: this.corporatePlan.location,
                tier: this.corporatePlan.tier,
                active: this.corporatePlan.active,
              },
            });
          }

          this.$emit("onSave");
          this.close();
        } catch (e) {
          this.addNotification({
            message: e?.response?.data?.message || "Error",
            icon: "mdi-alert",
            timeout: 10000,
          });
        }

        this.loading = false;
      }
    },

    async open(corporatePlan) {
      this.corporatePlanRef = corporatePlan;
      this.corporatePlan = { ...corporatePlan };
      this.dialog = true;
    },

    close() {
      this.refreshData();
      this.dialog = false;
    },

    refreshData() {
      (this.corporatePlanRef = null),
        (this.corporatePlan = {
          name: "",
          type: "",
          description: "",
          recurringPeriod: "",
          active: false,
          liveSessionAdd: 0,
          freeLiveSessionAdd: 0,
        });
    },
  },
};
</script>

<style scoped>
.v-input--is-disabled::v-deep .v-text-field__details {
  display: none;
}
</style>
